import { useEffect } from 'react';

import { Divider } from 'antd';
import { Modal } from 'antd';
import { useFormik } from 'formik';
import * as yup from 'yup';

import NextPrevButton from '@web/client/src/components/signUp/NextPrevButton';
import Image from '@web/client/src/components/ui/Image';
import { useActionAPI } from '@web/shared/apis';
import { Form, Input, Radio } from '@web/shared/components/form/Form';
import Upload from '@web/shared/components/form/Upload';
import { IAnyObject } from '@web/shared/types/etc';
import setFileData from '@web/shared/utils/fileData';
import validConfirm from '@web/shared/utils/validate';

import fileImage from '@web/client/src/assets/signUp/file.svg';

import { IStep } from './Step1';

export default function Step3({ onCancel, myData, changeStep }: IStep) {
  const { action } = useActionAPI('users');

  const validationSchema = {
    businessStatus: yup.string().required('사업 상태를 입력해주세요.'),
  };

  const formik = useFormik<IAnyObject>({
    initialValues: {
      businessStatus: '예비 창업자',
      businessNumber: '',
      businessOwner: '',
      businessDate: new Date().toISOString().split('T')[0],
      businessIntro: '',
      businessFile: [],
      sales: '',
      count: '',
      investment: '',
      business: '',
    },
    validationSchema: yup.object(validationSchema),
    onSubmit: async values => {
      const body = { ...values } as any;

      if (body.businessStatus === '창업자') {
        if (
          !body.business ||
          !body.businessDate ||
          !body.businessIntro ||
          !body.businessNumber ||
          !body.businessOwner ||
          !body.sales ||
          !body.count ||
          !body.investment
        ) {
          Modal.error({
            title: '사업자 정보 누락',
            content: '사업자 정보를 모두 채워주세요.',
          });
          return false;
        }
        const dataForTest = {
          businesses: [
            {
              b_no: body.businessNumber,
              start_dt: body.businessDate.split('-').join(''),
              p_nm: body.businessOwner,
            },
          ],
        };
        const isValid = await validConfirm(
          dataForTest,
          body.businessNumber,
          body.businessOwner,
        );
        if (!isValid) {
          return false;
        }
      }

      delete body.businessFile;
      values.businessFile.length
        ? (body.businessFile = values.businessFile[0].originFileObj)
        : (body.businessFile = []);

      await action(
        'put',
        {
          ...myData,
          ...body,
          role: myData.role.type,
          survey: myData.survey?.id || null,
          document: myData.document?.id || null,
          step: 3,
        },
        Number(myData.id),
      );
      return true;
    },
  });

  useEffect(() => {
    if (myData) {
      formik.setValues({
        businessStatus: myData.businessStatus
          ? myData.businessStatus
          : '예비 창업자',
        businessNumber: myData.businessNumber,
        businessOwner: myData.businessOwner,
        businessDate: myData.businessDate,
        businessIntro: myData.businessIntro,
        businessFile: setFileData(myData.businessFile),
        sales: myData.sales,
        count: myData.count,
        investment: myData.investment,
        business: myData.business,
      });
    }
  }, [myData]);

  useEffect(() => {
    const el = window.document.getElementsByClassName(
      'ant-input-textarea-show-count',
    )[0];

    el?.setAttribute(
      'data-count2',
      `${formik.values.businessIntro?.trim().length} / 30`,
    );
  }, [formik.values.businessIntro]);
  return (
    <>
      <div className="steps">
        <div className="header">
          <h1>3단계 인증 - 사업자 정보</h1>
        </div>
        <div className="content">
          <Form formik={formik}>
            <Radio
              label="사업 상태"
              name="businessStatus"
              options={[
                { label: '예비 창업자', value: '예비 창업자' },
                { label: '창업자', value: '창업자' },
                { label: '재창업', value: '재창업' },
              ]}
            />

            {formik.values.businessStatus === '창업자' && (
              <>
                <Input
                  name="businessNumber"
                  label="사업자 번호"
                  placeholder="숫자만 입력 ex)031-14-18923 -> 0311418923"
                  maxLength={10}
                />
                <Input name="businessOwner" label="사업자 대표 명" />
                <Input
                  name="businessDate"
                  label="사업자 개업 연월일"
                  type="date"
                />
                <Upload
                  label="사업자등록증 스캔파일 등록(선택)"
                  name="businessFile"
                  listType="text"
                  fileList={formik.values.businessFile}
                  iconRender={() => (
                    <Image width="2rem" src={fileImage} alt="icon" />
                  )}
                  maxCount={1}
                  accept="image/*"
                />

                <Input
                  name="businessIntro"
                  label="사업 소개 (30자 이상)"
                  minRows={3}
                  multiLine
                  showCount
                />

                <br />

                <Divider>사업 평가</Divider>

                <Input
                  name="sales"
                  label="매출 (최근 1년간 매출)"
                  buttonText="원"
                  buttonWidth="3.5rem"
                  disableButtonCursorPointer
                />
                <Input
                  name="count"
                  label="인력 (인원)"
                  buttonText="명"
                  buttonWidth="3.5rem"
                  disableButtonCursorPointer
                />
                <Input
                  name="investment"
                  label="투자금액"
                  buttonText="원"
                  buttonWidth="3.5rem"
                  disableButtonCursorPointer
                />
                <Input
                  name="business"
                  label="지원사업/정부사업 (과제를 진행한 경우 사업명 입력)"
                  placeholder="예) 초기창업패키지"
                />
                <br />
              </>
            )}
            <div className="description">
              <p>해당 단계를 작성하시면 3단계의 인증을 받으실 수 있습니다.</p>
              <p>상세 주소는 선택사항입니다.</p>
            </div>
          </Form>
        </div>

        <div className="buttonRap">
          <NextPrevButton onClick={() => changeStep(1)} prev text="이전 단계" />
          <NextPrevButton
            disabled={formik.isSubmitting}
            onClick={async e => {
              const isNoError = await formik.submitForm();
              if (isNoError) {
                onCancel?.(e);
              }
            }}
            loading={formik.isSubmitting}
            text="업데이트"
            next
          />
        </div>
      </div>
    </>
  );
}
