import { Select } from 'antd';
import clsx from 'clsx';

import { QuestionsStyled } from './style';

const answerOptions = [
  {
    label: '전혀 그렇지않다',
    value: '전혀 그렇지않다',
  },
  {
    label: '그렇지않다',
    value: '그렇지않다',
  },
  {
    label: '보통이다',
    value: '보통이다',
  },
  {
    label: '그렇다',
    value: '그렇다',
  },
  {
    label: '매우 그렇다',
    value: '매우 그렇다',
  },
];

type TAnswer =
  | null
  | '전혀 그렇지않다'
  | '그렇지않다'
  | '보통이다'
  | '그렇다'
  | '매우 그렇다';

export interface IQuestion {
  label: string;
  value: TAnswer;
}

interface IProps {
  questions: IQuestion[];
  setData: (v: any) => void;
}
export default function QuestionsModal({ questions, setData }: IProps) {
  const value = questions;

  const handleChange = (answer: TAnswer, i: number) => {
    setData(
      value.map(v => {
        if (v.label !== value[i].label) {
          return v;
        } else {
          return { ...v, value: answer };
        }
      }),
    );
  };

  return (
    <QuestionsStyled>
      {value &&
        value[0] &&
        value.map((q, i) => (
          <div key={q.label} className="item">
            <div className="Questions-label">{q.label}</div>

            <Select
              className={clsx('small', value[i].value && 'selected')}
              placeholder="선택하세요"
              onChange={answer => handleChange(answer as TAnswer, i)}
              value={q.value ? q.value : undefined}
              getPopupContainer={trigger => trigger.parentNode}
            >
              {answerOptions.map(option => (
                <Select.Option key={option.value} value={option.value}>
                  {option.label}
                </Select.Option>
              ))}
            </Select>
          </div>
        ))}
    </QuestionsStyled>
  );
}
