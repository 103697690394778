import { useEffect, useState } from 'react';

export default function withSplitting(getComponent: any) {
  const WithSplitting = () => {
    const [state, setState]: any = useState({
      Splitted: null,
    });

    useEffect(() => {
      getComponent()
        .then(({ default: Splitted }: any) => {
          setState({ Splitted });
        })
        .catch((error: any) => {
          const chunkFailedMessage = /Loading chunk [\d]+ failed/;
          if (chunkFailedMessage.test(error.message)) {
            window.location.reload();
          }
        });
    }, []);

    if (!state.Splitted) {
      return null;
    }

    return <state.Splitted />;
  };

  return WithSplitting;
}
