import { ReactNode } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { notification } from 'antd';
import { NotificationPlacement } from 'antd/lib/notification';
import QueryString from 'qs';

import { IAnyObject } from '@web/shared/types/etc';

interface ICloseOptions {
  message?: ReactNode;
  description?: ReactNode;
  placement?: NotificationPlacement;
}

export default function useModal() {
  const location = useLocation();
  const history = useHistory();

  const currentModalID = QueryString.parse(location.search, {
    ignoreQueryPrefix: true,
  })?.modal;

  const open = (modalID: string, params: IAnyObject = {}) => {
    const qsParmas = QueryString.stringify(params);

    history.push(
      `${location.pathname}?modal=${modalID}${qsParmas ? `&${qsParmas}` : ''}`,
    );
  };

  const close = (options: ICloseOptions = {}) => {
    if (options.message) {
      notification.success({
        message: options.message,
        description: options.description,
        placement: options.placement,
      });
    }

    history.goBack();
  };

  return { open, close, currentModalID };
}
