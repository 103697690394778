import { ReactNode } from 'react';

import { IMenu } from '.';
import { useGetMe } from '../apis/users';
import Header from '../components/header/Header';
import { MangeTemplateStyled } from './styled';

export interface IMentoTemplateProps {
  children?: ReactNode;
  menus?: IMenu[] | undefined;
  error?: string;
}

export default function MentoTemplate({
  children,
  menus,
  error,
}: IMentoTemplateProps) {
  const { data } = useGetMe();
  return (
    <MangeTemplateStyled>
      <Header menus={menus} data={data} />
      <div className="UI-content">{children}</div>
    </MangeTemplateStyled>
  );
}
