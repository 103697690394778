
import { useEffect, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';

import { Button, ModalProps } from 'antd';
import { useFormik } from 'formik';

import Modal from '@web/client/src/components/ui/Modal';
import useModal from '@web/client/src/hooks/useModal';
import { useTrans } from '@web/client/src/hooks/useTrans';
import { useActionAPI } from '@web/shared/apis';
import { Form, Input } from '@web/shared/components/form/Form';
import useAuth from '@web/shared/hooks/useAuth';
import { IAnyObject } from '@web/shared/types/etc';

import ForgetModal from '../forget/ForgetModal';
import { SignInStyled } from './signInStyled';

interface ISignInModal extends ModalProps {
  noSignUp?: boolean;
}

export default function SignInModal({ noSignUp, ...props }: ISignInModal) {
  const [queryData, setQueryData] = useState<IAnyObject | null>(null);
  const { signIn } = useAuth();
  const { close, open } = useModal();
  const history = useHistory();
  const location = useLocation();
  const $ = useTrans();

  const formik = useFormik({
    initialValues: {
      username: '',
      password: '',
    },
    onSubmit: async values => {
      const body = { ...values } as any;
      const res: IAnyObject = await signIn(body.username, body.password);
      values.username = '';
      values.password = '';

      // IP 변경 확인 및 이메일 인증 요청 부분 삭제
      close({
        message: `${$('로그인이 완료되었습니다.')}`,
        placement: 'topLeft',
      });
    },
  });

  return (
    <>
      <Modal
        modalID="SignIn"
        title={`${$('로그인')}`}
        small
        centered
        destroyOnClose
        {...props}
      >
        <SignInStyled>
          <Form formik={formik} hasEnter>
            <Input name="username" label="Account" />
            <Input
              type="password"
              name="password"
              label="Password"
              password
              autoComplete="on"
            />
          </Form>

          {!noSignUp ? (
            <div className="forget">
              <span
                onClick={() => {
                  history.push(location.pathname);
                  open('Forget');
                }}
              >
                  {`${$('계정이 생각나지 않으신가요?')}`}
              </span>
            </div>
          ) : (
            <div className="forget">
              <span
                onClick={() => {
                  window.location.href = 'https://www.gef.kr/';
                }}
              >
                {`${$('메인 페이지로 돌아가기')}`}
              </span>
            </div>
          )}

          <Button
            loading={formik.isSubmitting}
            onClick={formik.submitForm}
            type="primary"
            block
          >
            {`${$('로그인')}`}
          </Button>

          {!noSignUp && (
            <div className="signUpLink">
              {`${$('계정이 없으신가요?')}`}&nbsp;
              <Link className="" to="/sign/up">
                <em>{`${$('회원가입')}`}</em>
              </Link>
            </div>
          )}
        </SignInStyled>
      </Modal>
      <ForgetModal />
    </>
  );
}

/*import { useEffect, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';

import { Button, ModalProps } from 'antd';
import { useFormik } from 'formik';

import Modal from '@web/client/src/components/ui/Modal';
import useModal from '@web/client/src/hooks/useModal';
import { useTrans } from '@web/client/src/hooks/useTrans';
import { useActionAPI } from '@web/shared/apis';
import { Form, Input } from '@web/shared/components/form/Form';
import useAuth from '@web/shared/hooks/useAuth';
import { IAnyObject } from '@web/shared/types/etc';

// import facebook from '@web/client/src/assets/signUp/facebook.webp';
// import google from '@web/client/src/assets/signUp/google.webp';
// import kakao from '@web/client/src/assets/signUp/kakao.webp';
// import naver from '@web/client/src/assets/signUp/naver.webp';
import ForgetModal from '../forget/ForgetModal';
import EmailValidation from '../signUp/EmailValidation';
// import Image from '../ui/Image';
import { SignInStyled } from './signInStyled';

// const loginCase = [
//   {
//     kor: '구글',
//     eng: 'google',
//     src: google,
//   },
//   {
//     kor: '네이버',
//     eng: 'naver',
//     src: naver,
//   },
// 추후 추가
// {
//   kor: '카카오',
// eng: 'kakao',
//   value: kakao,
// },
// {
//   kor: '페이스북',
// eng: 'facebook',
//   value: facebook,
// },
// ];

interface ISignInModal extends ModalProps {
  noSignUp?: boolean;
}

export default function SignInModal({ noSignUp, ...props }: ISignInModal) {
  const [queryData, setQueryData] = useState<IAnyObject | null>(null);
  const [userData, setUserData] = useState<IAnyObject | null>(null);
  const [completed, setCompleted] = useState<boolean>(false);
  const [tempToken, setTempToken] = useState<string>('');
  const { action: tempACtion } = useActionAPI('temps');
  const { action: userAction } = useActionAPI('users');

  const { signIn } = useAuth();
  const { close, open } = useModal();
  const history = useHistory();
  const location = useLocation();
  const $ = useTrans();

  const formik = useFormik({
    initialValues: {
      username: '',
      password: '',
    },
    onSubmit: async values => {
      const body = { ...values } as any;
      const res: IAnyObject = await signIn(body.username, body.password);
      values.username = '';
      values.password = '';

      if (res.ip) {
        const { token, ip, user } = res;
        setTempToken(token);
        const query: IAnyObject = {
          to: user.email,
          lang: user.phone.split(' ')[0] === '+82' ? 'kor' : 'eng',
          type: 'newLogin',
          ip: ip,
        };

        const id = await tempACtion('post', query);
        setUserData({ ...user, ip: ip });
        setQueryData({ ...query, id });
        return;
      }

      close({
        message: `${$('로그인이 완료되었습니다.')}`,
        placement: 'topLeft',
      });
    },
  });

  useEffect(() => {
    if (completed) {
      userAction(
        'put',
        { ipList: [...(userData?.ipList as any), userData?.ip] },
        userData?.id,
      );
      window['localStorage'].setItem('token', tempToken);
      close({
        message: `${$('로그인이 완료되었습니다.')}`,
        placement: 'topLeft',
      });
      setCompleted(false);
      setTempToken('');
    }
  }, [completed]);

  return (
    <>
      <Modal
        modalID="SignIn"
        title={`${$('로그인')}`}
        small
        centered
        destroyOnClose
        {...props}
      >
        <SignInStyled>
          <Form formik={formik} hasEnter>
            <Input name="username" label="Account" />
            <Input
              type="password"
              name="password"
              label="Password"
              password
              autoComplete="on"
            />
          </Form>

          {!noSignUp ? (
            <div className="forget">
              <span
                onClick={() => {
                  history.push(location.pathname);
                  open('Forget');
                }}
              >
                {`${$('계정이 생각나지 않으신가요?')}`}
              </span>
            </div>
          ) : (
            <div className="forget">
              <span
                onClick={() => {
                  window.location.href = 'https://www.gef.kr/';
                }}
              >
                {`${$('메인 페이지로 돌아가기')}`}
              </span>
            </div>
          )}

          <Button
            loading={formik.isSubmitting}
            onClick={formik.submitForm}
            type="primary"
            block
          >
            {`${$('로그인')}`}
          </Button>

          {!noSignUp && (
            <div className="signUpLink">
              {`${$('계정이 없으신가요?')}`}&nbsp;
              <Link className="" to="/sign/up">
                <em>{`${$('회원가입')}`}</em>
              </Link>
            </div>
          )}

          {/* <Divider>또는</Divider>
          <div className="itemWrapper">
            {loginCase.map(provider => (
              <a
                className="item"
                key={provider.eng}
                href={`https://api.gef.kr/connect/${provider.eng}`}
                // href={`http://localhost:4000/connect/${provider.eng}`}
              >
                <Image src={provider.src} alt={`${provider.eng}_login`} />
                <div className="text">{provider.kor}</div>
              </a>
            ))}
          </div> */ //}
          /*
        </SignInStyled>
      </Modal>
      <ForgetModal />
      <EmailValidation
        visible={queryData !== null}
        closeModal={() => setQueryData(null)}
        setCompleted={(v: boolean) => setCompleted(v)}
        data={queryData}
      />
    </>
  );
}*/