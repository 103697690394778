import { AnimateSharedLayout, motion } from 'framer-motion';
import styled from 'styled-components';

import { IMenu } from '@web/shared/templates';

import MenuItem from './MenuItem';

const MotioStyled = styled(motion.div)`
  position: relative;
`;

interface MenuListTypes {
  menus: IMenu[] | undefined;
  active: IMenu | undefined;
  onClickMenu: any;
}

export default function MainMenuList({
  menus,
  active,
  onClickMenu,
}: MenuListTypes) {
  return (
    <AnimateSharedLayout>
      <div className="menu-list">
        {menus &&
          menus.map((v, key) => (
            <MotioStyled
              className={active?.link === v.link ? 'active' : ''}
              key={key}
              onClick={() => onClickMenu(v)}
            >
              <MenuItem name={v.name} link={v.link} />

              {active?.link === v.link && (
                <motion.div
                  className="menuActiveLine"
                  layoutId="menuActiveLine"
                  initial={false}
                  transition={{
                    type: 'spring',
                    stiffness: 500,
                    damping: 35,
                  }}
                />
              )}
            </MotioStyled>
          ))}
      </div>
    </AnimateSharedLayout>
  );
}
