import { UploadFile } from 'antd/lib/upload/interface';

import { getFileURL } from '@web/shared/utils/strapi';

import { IAnyObject } from '../types/etc';
import { IFile } from '../types/strapi';

const setFileData = (file: IFile): UploadFile<IAnyObject>[] => {
  if (file) {
    return [
      {
        uid: '0',
        name: file.name,
        status: 'done',
        url: getFileURL(file, 'thumbnail'),
      },
    ];
  }
  return [];
};

export default setFileData;
