import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import AOS from 'aos';
import { enableES5 } from 'immer';
import { oc } from 'ts-optchain';

import Shared from '@web/shared/Shared';

import App from '~/App';
import store from '~/redux/store';

import * as Bridge from './language/api/bridgeApi';
import * as i18n from './language/utils/i18nUtils';

import 'aos/dist/aos.css';

declare global {
  interface Document {
    documentMode?: number;
  }

  interface Window {
    __FRONTEND_TYPE__: 'client' | 'admin' | 'mento';
  }
}

window.__FRONTEND_TYPE__ = 'client';

enableES5();

if (window.document.documentMode) {
  if (
    window.confirm(
      '호환되지 않는 브라우저입니다 익스플로러 외의 브라우저를 이용해주세요. 확인을 누르시면 크롬 다운로드 페이지로 이동합니다.',
    )
  ) {
    window.location.href =
      'https://www.google.co.kr/chrome/?brand=CHBD&gclid=Cj0KCQiAtJeNBhCVARIsANJUJ2HWZn4lPITo80OnGHZ2bVHHnf1lqhKoVcVR1Kqzs4SNLYgFHwB2fjcaAg_LEALw_wcB&gclsrc=aw.ds';
  }
}

AOS.init({
  duration: 500,
  once: false,
  mirror: true,
});

window.onload = async () => {
  try {
    const { result } = await Bridge.getNativeInfo();
    const nativeLang = oc(result).language();

    await i18n.init(nativeLang as string);

    render();
  } catch (e) {
    console.error(e);
  }
};

function render() {
  ReactDOM.render(
    <Provider store={store}>
      <Shared store={store}>
        <App />
      </Shared>
    </Provider>,
    document.getElementById('root'),
  );
}
