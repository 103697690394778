import { useContext } from 'react';

import { Checkbox } from 'antd';
import { FormikContext } from 'formik';

import { FormField } from '@web/shared/components/form/Form';

interface CheckBoxTypes {
  name: string;
  label?: string;
  helperText?: string;
  options: Array<any>;
  radio?: boolean;
}

export default function CheckBox({
  name,
  label,
  helperText,
  options,
  radio = false,
}: CheckBoxTypes) {
  const formik = useContext(FormikContext);
  const error = formik.errors[name];

  const onChange = (values: Array<string | number | boolean>) => {
    let value: any[] = values;

    if (radio) {
      const findValue = values.find(x => x !== formik.values[name][0]);

      value = findValue ? [findValue] : [];
    }

    formik.setFieldValue(name, value);
  };

  return (
    <FormField
      label={label}
      error={error ? helperText || (error as string) : ''}
    >
      <Checkbox.Group
        options={options}
        value={formik.values[name]}
        onChange={onChange}
      />
    </FormField>
  );
}
