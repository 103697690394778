import { useEffect } from 'react';
import { useState } from 'react';

import { throttle } from 'lodash';

import Icon from '@web/shared/components/icon/Icon';

import { ButtonStyle } from './buttonStyle';

export default function ScrollButton() {
  const [hide, setHide] = useState(false);

  useEffect(() => {
    const onScroll = (): void => {
      const scrollPosition = document.documentElement.scrollTop;
      const scrollHeight = document.body.offsetHeight;

      const docHeight = document.body.scrollHeight;
      if (scrollPosition + scrollHeight > docHeight * 0.9) {
        setHide(true);
      } else {
        setHide(false);
      }
    };

    window.addEventListener('scroll', throttle(onScroll, 1000));
    return () => {
      window.removeEventListener('scroll', throttle(onScroll, 1000));
    };
  }, []);

  return (
    <ButtonStyle>
      <div className={`mouse ${hide && 'hide'}`}>
        <Icon icon="keyboard_double_arrow_down" />
      </div>
    </ButtonStyle>
  );
}
