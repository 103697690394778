import { IRoute } from '@web/shared/routes';

import splitting from './splitting';

const Home = splitting(() => import('~/pages/Home'));
const Gef = splitting(() => import('~/pages/Gef'));
const Business = splitting(() => import('~/pages/Business'));
const Coaching = splitting(() => import('~/pages/Coaching'));
const Community = splitting(() => import('~/pages/Community'));
const CommunityDetail = splitting(() => import('~/pages/CommunityDetail'));
const InquiryWrite = splitting(() => import('~/pages/InquiryWrite'));
const DataCenter = splitting(() => import('~/pages/DataCenter'));
const SignUp = splitting(() => import('~/pages/SignUp'));
const AuthRedirect = splitting(() => import('~/pages/AuthRedirect'));
const MyPage = splitting(() => import('~/pages/MyPage'));
const Inquiry = splitting(() => import('~/pages/Inquiry'));
const InquiryDetail = splitting(() => import('~/pages/InqiryDetail'));
const Partner = splitting(() => import('~/pages/Partners'));
const PartnerUser = splitting(() => import('~/pages/PartnerUser'));

const routes: IRoute[] = [
  {
    path: '/',
    component: Home,
    template: 'App',
  },
  {
    path: '/my-page',
    component: MyPage,
    template: 'App',
    templateProps: {
      theme: 'black',
      removeFooter: true,
    },
  },
  {
    path: '/sign/up',
    component: SignUp,
  },
  {
    path: '/data-center',
    template: 'App',
    component: DataCenter,
    templateProps: {
      theme: 'black',
    },
  },
  {
    path: '/gef',
    template: 'App',
    component: Gef,
    templateProps: {
      theme: 'white_line',
    },
  },
  {
    path: '/coaching',
    template: 'App',
    component: Coaching,
    templateProps: {
      theme: 'white_line',
    },
  },
  {
    path: '/business',
    template: 'App',
    component: Business,
    templateProps: {
      theme: 'white_line',
    },
  },
  {
    path: '/404',
    template: 'App',
    component: Home,
    templateProps: {
      error: '🚧 준비중인 페이지입니다.',
    },
  },
  {
    path: '/community/list/:category',
    template: 'App',
    component: Community,
    templateProps: {
      theme: 'black',
    },
  },
  {
    path: '/community/view/:id',
    template: 'App',
    component: CommunityDetail,
    templateProps: {
      theme: 'black',
    },
  },
  {
    path: '/inquiry/list',
    template: 'App',
    component: Inquiry,
    templateProps: {
      theme: 'black',
    },
  },
  {
    path: '/inquiry/view/:id',
    template: 'App',
    component: InquiryDetail,
    templateProps: {
      theme: 'black',
    },
  },
  {
    path: '/inquiry/write',
    template: 'App',
    component: InquiryWrite,
    templateProps: {
      theme: 'black',
      removeScrollBtn: true,
    },
  },
  {
    path: '/auth/:provider/redirect',
    component: AuthRedirect,
    templateProps: {
      theme: 'black',
    },
  },
  {
    path: '/partners',
    component: Partner,
    template: 'Admin',
  },
  {
    path: '/partnerusers',
    component: PartnerUser,
    template: 'Admin',
  },
];

export default routes;
