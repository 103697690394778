import { useHistory } from 'react-router-dom';

import { Button } from 'antd';
import { useFormik } from 'formik';
import * as yup from 'yup';

import Image from '@web/client/src/components/ui/Image';
import { Form, Input } from '@web/shared/components/form/Form';

import faq from '@web/client/src/assets/icons/faq.svg';
import message from '@web/client/src/assets/icons/message.svg';
import notice from '@web/client/src/assets/icons/notice.svg';
import save from '@web/client/src/assets/icons/save.svg';
import top from '@web/client/src/assets/icons/top.svg';
import map from '@web/client/src/assets/index/map.webp';

import Rap from '../contentRap/Rap';
import Logo from '../header/Logo';
import { confirm } from '../ui/Confirm';
import { sendEmail } from '../utils/util';
import { FooterDiv } from './styled';

const boardMenuList = [
  {
    title: '공지사항',
    enTitle: 'Notice',
    icon: notice,
    link: '/community/list/notice',
  },
  {
    title: '1:1 문의',
    enTitle: 'Q&A',
    icon: message,
    link: '/inquiry/list',
  },
  {
    title: '자료실',
    enTitle: 'Data download',
    icon: save,
    link: '/community/list/files',
  },
  {
    title: '자주묻는 질문답변',
    enTitle: 'FAQ',
    icon: faq,
    link: '/community/list/faq',
  },
];

export default function Footer() {
  const history = useHistory();

  const validationSchema = {
    content: yup.string().required('내용을 입력해주세요'),
    from: yup
      .string()
      .email('이메일 형식으로 입력해주세요.')
      .required('이메일을 입력해주세요'),
    title: yup.string().required('제목을 입력해주세요'),
  };

  const formik = useFormik({
    initialValues: {
      from: '',
      title: '',
      content: '',
    },
    validationSchema: yup.object(validationSchema),
    onSubmit: async values => {
      const query = {
        to: '2gether@gef.kr',
        from: values.from,
        title: values.title,
        content: values.content,
        type: 'footer',
      };
      await sendEmail(query);
      values.from = '';
      values.title = '';
      values.content = '';
      window.scrollTo(0, 0);

      confirm({
        title: '감사합니다.',
        content:
          '보내주신 내용을 바탕으로 전문가의 컨설팅과 정확한 데이터로 성공 창업에 도움을 드리겠습니다.',
      });
    },
  });

  return (
    <FooterDiv id="footer" className="UI-footer">
      <Rap>
        <div className="getInTouch" data-aos="fade-up">
          <div className="title">
            Get in <br /> Touch
          </div>
          <div className="subTitle">
            성공 창업을 원하시면 언제든 메세지를 남겨 주세요.
            <br />
            전문가의 컨설팅과 정확한 데이터로 성공 창업에 도움을 드리겠습니다.
          </div>

          <Form formik={formik}>
            <Input name="from" type="email" placeholder="Email" required />
            <Input name="title" type="text" placeholder="Title" required />
            <Input
              name="content"
              placeholder="Message"
              className="textarea"
              multiLine
              required
            />
            <Button
              onClick={formik.submitForm}
              loading={formik.isSubmitting}
              disabled={formik.isSubmitting}
            >
              SEND MESSAGE
            </Button>
          </Form>
        </div>

        <div className="right" data-aos="fade-up">
          <div className="side">
            <div className="contactUs">
              <div className="title">Contact Us</div>
              <div className="subTitle">
                <p>서울특별시 강남구 테헤란로</p>
                <p> 427, 8층(삼성동, 위워크타워)</p>
                <p>Email: onestep@gef.kr</p>
                <p>Phone: +82 (0) 10-5364-6761</p>
                <p>Fax: +82 (0) 2-3452-8484</p>
              </div>
            </div>

            <div className="board">
              <div className="title">Board</div>
              <div className="menu">
                {boardMenuList.map((v, i) => (
                  <div
                    className="item"
                    key={i}
                    onClick={() => history.push(v.link)}
                  >
                    <div className="iconBox">
                      <Image width="1.5rem" src={v.icon} alt="icon" />
                    </div>
                    <div className="name">
                      {v.title} <span>|</span> {v.enTitle}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className="locationMap">
            <div className="title locationTitle">Location map</div>
            <div className="mapBox">
              <Image
                width="120%"
                height="120%"
                src={map}
                onClick={() => window.open('http://naver.me/FIpVeBFr')}
              />
            </div>
          </div>
        </div>
      </Rap>

      <div className="bot">
        <Rap>
          <Logo />
          <div className="topBtn">
            <Image
              src={top}
              width="2rem"
              alt="logo"
              onClick={() => {
                window.scrollTo({
                  top: 0,
                  behavior: 'smooth',
                });
              }}
            />
          </div>
        </Rap>
      </div>
    </FooterDiv>
  );
}
