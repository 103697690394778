import { ReactNode } from 'react';

import Admin, { IAdminTemplateProps } from './AdminTemplate';
import App from './AppTemplate';
import Default from './DefaultTemplate';
import Mento from './MentoTemplate';

const templates = {
  App,
  Admin,
  Mento,
  Default,
};

export interface IMenu {
  name: string;
  icon?: ReactNode;
  subMenu?: IMenu[];
  link?: string;
}

export type ITemplateProps = IAdminTemplateProps;
export type TTemplateKey = keyof typeof templates;
export default templates;
