import DaumPostCode from 'react-daum-postcode';

import { ModalProps } from 'antd';
import styled from 'styled-components';

import { IAnyObject } from '@web/shared/types/etc';

import Modal from '../ui/Modal';

const ModalStyled = styled(Modal)`
  .ant-modal-body {
    padding: 24px !important;
  }
  .ant-modal-close {
    top: 0;
    right: 0;
  }
  .ant-modal-close-x {
    width: 24px !important;
    height: 24px !important;
  }
`;

interface IAddress extends ModalProps {
  setData: (v: string) => void;
  close: () => void;
}

export default function Address({ setData, close, ...props }: IAddress) {
  const handleComplete = (data: IAnyObject) => {
    let fullAddress = data.address;
    let extraAddress = '';
    if (data.addressType === 'R') {
      if (data.bname !== '') {
        extraAddress += data.bname;
      }
      if (data.buildingName !== '') {
        extraAddress +=
          extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddress += extraAddress !== '' ? ` (${extraAddress})` : '';
    }
    setData(fullAddress);
    close();
  };

  return (
    <ModalStyled
      {...props}
      modalID="Address"
      centered
      destroyOnClose
      width={512}
    >
      <DaumPostCode onComplete={handleComplete} />
    </ModalStyled>
  );
}
