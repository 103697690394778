import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { Modal } from 'antd';
import { useFormik } from 'formik';

import NextPrevButton from '@web/client/src/components/signUp/NextPrevButton';
import { useActionAPI } from '@web/shared/apis';
import { Form } from '@web/shared/components/form/Form';
import { IAnyObject } from '@web/shared/types/etc';
import { getQS } from '@web/shared/utils';

import QuestionsModal, { IQuestion } from '../QuestionsModal';
import questions from '../questions';

export interface IStep {
  onCancel: (e: React.MouseEvent<any>) => void;
  myData: IAnyObject;
  changeStep: (v: number) => void;
}

export default function Step1({ onCancel, myData }: IStep) {
  const qs = getQS();
  const history = useHistory();
  const location = useLocation();

  const [questionValue, setQuestionValue] = useState<IQuestion[]>(
    myData?.questions ? myData?.questions : questions,
  );

  const { action } = useActionAPI('users');

  const formik = useFormik({
    initialValues: {
      questions: questions,
    },
    onSubmit: async values => {
      const body = { ...values } as any;

      const noAnswer = questionValue.some((q: IAnyObject) => !q.value);
      if (noAnswer) {
        Modal.error({
          title: '답변 필요',
          content: '모든 질문에 답해주세요.',
        });
        return;
      }

      await action(
        'put',
        {
          ...myData,
          ...body,
          role: myData.role.type,
          step: myData.step < 1 ? 1 : myData.step,
          survey: myData.survey?.id || null,
          document: myData.document?.id || null,
        },
        Number(myData.id),
      );
      if (qs.steps) {
        history.push(`${location.pathname}?modal=UserAdd&steps=1`);
      }
    },
  });

  useEffect(() => {
    formik.setFieldValue('questions', questionValue);
  }, [questionValue]);

  return (
    <div className="steps">
      <div className="header">
        <h1>1단계 인증 - 필수 질문</h1>
      </div>
      <div className="content">
        <Form formik={formik}>
          <QuestionsModal
            questions={questionValue}
            setData={v => setQuestionValue(v)}
          />
          <div className="description">
            <p>해당 단계를 작성하시면 1단계의 인증을 받으실 수 있습니다.</p>
            <p>모든 항목은 필수입니다.</p>
          </div>
        </Form>
      </div>

      <div className="buttonRap">
        <NextPrevButton onClick={onCancel} prev text="차후 진행" />
        <NextPrevButton
          disabled={formik.isSubmitting}
          onClick={formik.submitForm}
          loading={formik.isSubmitting}
          text="업데이트"
          next
        />
      </div>
    </div>
  );
}
