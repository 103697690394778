import { ReactNode, useState } from 'react';
import { useEffect } from 'react';
import { useResizeDetector } from 'react-resize-detector/build/withPolyfill';
import { useLocation } from 'react-router-dom';

import { motion } from 'framer-motion';

import { IRouteProp } from '.';
import Middleware from '../middleware/Middleware';
import { TPromiseReturn } from '../middleware/type';
import { ITemplateProps, TTemplateKey } from '../templates';

const variants = {
  init: {
    opacity: 0,
  },

  in: {
    opacity: 1,
  },
};

interface IPageComponentProps {
  path: string;
  children: ReactNode;
}

declare global {
  interface Window {
    _PAGE_MOVE_COUNT_: number;
  }
}

function PageComponent({ path, children }: IPageComponentProps) {
  const location = useLocation();
  const { height, ref } = useResizeDetector();
  const [zIndex] = useState(window._PAGE_MOVE_COUNT_);

  useEffect(() => {
    window._PAGE_MOVE_COUNT_ = (window._PAGE_MOVE_COUNT_ || 0) + 1;
  }, [location.pathname]);

  return (
    <>
      <motion.div
        className="sharedPageMotion"
        initial="init"
        animate="in"
        variants={variants}
        transition={{ duration: 0.6 }}
        style={{ zIndex }}
        ref={ref}
        data-path={path}
      >
        {children}
      </motion.div>

      <div style={{ height: height === 0 ? window.screen.height : height }} />
    </>
  );
}

function TemplateActionComponent({
  children,
  template,
  templateProps,
  onChangePage,
}: {
  children: ReactNode;
  template?: TTemplateKey;
  templateProps?: ITemplateProps;
  onChangePage: (
    template: TTemplateKey,
    templateProps?: ITemplateProps,
  ) => void;
}) {
  useEffect(() => {
    if (template) {
      onChangePage(template, templateProps);
    }
  }, []);

  return <>{children}</>;
}

export default function URoute({
  path,
  template,
  templateProps,
  component: Component,
  middleware,
  onMiddlewareError,
  onMiddlewareSuccess,
  dispatch,
  history,
  onChangePage,
}: IRouteProp) {
  const _onMiddlewareError = (payload: TPromiseReturn) => {
    onMiddlewareError && onMiddlewareError(dispatch, history, payload);
  };
  const _onMiddlewareSuccess = (payload: TPromiseReturn) => {
    onMiddlewareSuccess && onMiddlewareSuccess(dispatch, history, payload);
  };

  return (
    <>
      {middleware && middleware.length ? (
        <Middleware
          middlewares={middleware}
          onMiddlewareError={_onMiddlewareError}
          onMiddlewareSuccess={_onMiddlewareSuccess}
        >
          <TemplateActionComponent
            template={template}
            templateProps={templateProps}
            onChangePage={onChangePage}
          >
            <PageComponent path={path}>
              <Component />
            </PageComponent>
          </TemplateActionComponent>
        </Middleware>
      ) : (
        <TemplateActionComponent
          template={template}
          templateProps={templateProps}
          onChangePage={onChangePage}
        >
          <PageComponent path={path}>
            <Component />
            {/* {template === 'App' && <Footer />} */}
          </PageComponent>
        </TemplateActionComponent>
      )}
    </>
  );
}
