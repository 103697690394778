import { Input as AntInput } from 'antd';
import styled from 'styled-components';

interface FromWrapperProps {
  len: number;
  isError: boolean;
}

export const FormWrapper = styled.form<FromWrapperProps>`
  @keyframes twiddle {
    0% {
      transform: translateY(1rem);
    }
    10% {
      transform: translateY(-1rem);
    }
    20% {
      transform: translateY(0.5rem);
    }
    30% {
      transform: translateY(-0.5rem);
    }
    40% {
      transform: translateY(0.3rem);
    }
    50% {
      transform: translateY(-0.3rem);
    }
    50% {
      transform: translateY(0.2rem);
    }
    60% {
      transform: translateY(-0.2rem);
    }
    70% {
      transform: translateY(0.1rem);
    }
    80% {
      transform: translateY(-0.1rem);
    }
    100% {
      transform: translateY(0);
    }
  }
  animation: ${props => (props.isError ? 'twiddle .2s' : '')};
  & fieldset {
    display: flex;
    justify-content: space-between;
    width: 90%;
    margin: 0 auto 1rem;
    -webkit-user-select: none;
    user-select: none;
    & input {
      flex-basis: ${props => `${90 / props.len}%`};
      height: 4rem;
      text-align: center;
      font-size: 2rem;
      font-weight: 600;
      border: 0;

      background-color: transparent;
      border-bottom: 4px solid #878787;
      color: transparent;
      text-shadow: ${props =>
        props.isError ? '0 0 0 red ' : '0 0 0 #4e36cc '};
      &:valid {
        border-bottom: ${props =>
          props.isError ? '4px solid red' : '4px solid #4e36cc'};
      }
      &:focus {
        outline: none;
        font-size: 2.5rem;
      }
    }
  }
`;

export const TableDiv = styled.div`
  .ant-select-selector {
    border: 1px solid rgba(0, 0, 0, 0.1) !important;
    border-radius: 4px !important;
    padding-left: 11px !important;
  }

  .error .ant-select-selector {
    border-color: red !important;
  }
  .auto {
    width: 200;
  }
`;

export interface ButtonProps {
  buttonWidth?: string;
  disableButtonCursorPointer?: boolean;
}

export const InputBox = styled.div<ButtonProps>`
  .ant-input {
    border: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding-left: 0;
  }

  .ant-input-group-addon {
    ${props => props.buttonWidth && `width: ${props.buttonWidth};`}

    .ant-btn {
      ${props => props.disableButtonCursorPointer && `cursor: default;`}
    }
  }

  .validateState {
    display: flex;
    align-items: center;
    font-family: GmarketSansTTF;
    font-size: 0.75rem;
    font-weight: 500;
    color: #7c8393;
    user-select: none;
    -webkit-user-drag: none;

    span {
      margin: 0 8px;
    }
  }

  &.simple {
    .ant-input {
      border: none;

      &::placeholder {
      }
    }
  }
`;

export const Overlay = styled.div<{
  visible: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 1001;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);

  .daumPostcodeWrap {
    position: relative;
    max-width: 600px;
    width: 100%;

    .closeBtn {
      position: absolute;
      left: 100%;
      top: 0;
      width: 3rem;
      height: 3rem;
      border: none;
      background: #4e36cc;
      color: #fff;
      line-height: 1em;
    }
  }

  ${props =>
    !props.visible &&
    `
        display: none;
    `}
`;

export const HelperTxtDiv = styled.div`
  position: relative;
  margin-bottom: 10px;

  .helperText {
    font-size: 11px;
    position: absolute;
    z-index: 1;
    color: red;
  }

  input,
  textarea {
    border-radius: 0;
  }

  &.error {
    select,
    textarea,
    input {
      border-color: red !important;
    }
  }
`;

export const ListWrapper = styled.ul`
  margin: 0;
`;

export const List = styled.li`
  position: relative;
  margin: 0.3rem 0;
`;

export const Title = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  width: 98%;
  white-space: nowrap;
`;

export const DeleteButton = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
`;

export const InputStyled = styled(AntInput)`
  .ant-input-group-addon {
    overflow: visible !important;
    border: 0 !important;
  }
  .ant-select-dropdown {
    border: solid 1px #d9d4f3;
  }

  .ant-select-selection-item {
    line-height: 23px !important;
    font-size: 11px;
  }
`;
