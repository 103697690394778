import { useContext } from 'react';

import { DatePicker as AntDate } from 'antd';
import { FormikContext } from 'formik';
import moment from 'moment';

import { useTrans } from '@web/client/src/hooks/useTrans';
import { FormField } from '@web/shared/components/form/Form';

interface DatePickerTypes {
  name: string;
  hasTime?: boolean;
  label?: string;
  helperText?: string;
  type?: string;
}

export default function DatePicker({
  name,
  hasTime,
  label,
  helperText,
}: DatePickerTypes) {
  const formik = useContext(FormikContext);
  const error = formik.errors[name];
  const $ = useTrans();

  const onChange = (date: any, dateString: string) => {
    formik.setFieldValue(name, dateString);
  };

  moment.updateLocale('en', {
    weekdaysMin: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
  });

  return (
    <FormField
      label={label}
      error={error ? helperText || (error as string) : ''}
    >
      {!hasTime ? (
        <AntDate
          getPopupContainer={trigger => trigger.parentElement as HTMLElement}
          onChange={onChange}
          defaultValue={formik.values[name]}
          renderExtraFooter={() => (
            <div>{`${$(
              '시간은 한국시간 KST (UTC+9)를 기준으로 합니다.',
            )}`}</div>
          )}
        />
      ) : (
        <AntDate
          format="YYYY-MM-DD HH:mm"
          getPopupContainer={trigger => trigger.parentElement as HTMLElement}
          value={formik.values[name] ? moment(formik.values[name]) : null}
          onChange={onChange}
          showTime={{
            defaultValue: moment('00:00:00', 'HH:mm:ss'),
            format: 'HH:mm',
          }}
          minuteStep={10}
          renderExtraFooter={() => (
            <div>{`${$(
              '시간은 한국시간 KST (UTC+9)를 기준으로 합니다.',
            )}`}</div>
          )}
        />
      )}
    </FormField>
  );
}
