import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

export default function AutoScrollTop() {
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (history.action === 'PUSH') {
      window.scrollTo(0, 0);
    }
  }, [location.pathname]);

  return null;
}
