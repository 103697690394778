import { useContext, useEffect, ReactNode } from 'react';

import { AnimatePresence, motion } from 'framer-motion';
import styled from 'styled-components';

import { useGetMe } from '@web/shared/apis/users';
import sharedContext from '@web/shared/contexts/SharedContext';
import useAuth from '@web/shared/hooks/useAuth';
import { setUser } from '@web/shared/redux/slices/auth';

interface IBootstrapProps {
  children: ReactNode;
}

const Loading = styled.div`
  .bootstrapLoading {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #333;
  }
`;

export default function Bootstrap({ children }: IBootstrapProps) {
  const { store } = useContext(sharedContext);
  const { isSignIn } = useAuth();
  const { data: user } = useGetMe({ enabled: isSignIn });

  const isFinish = isSignIn ? Boolean(user) : true;

  useEffect(() => {
    if (user) {
      store?.dispatch(setUser(user));
    }
  }, [user]);

  return (
    <>
      <AnimatePresence>
        {isFinish && (
          <motion.div
            key="bootstrap-render"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
          >
            {children}
          </motion.div>
        )}

        {!isFinish && (
          <Loading>
            <motion.div
              className="bootstrapLoading"
              key="bootstrap-loading"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.3 }}
            >
              로딩중...
            </motion.div>
          </Loading>
        )}
      </AnimatePresence>
    </>
  );
}
