const questions = [
  { label: '현재 사업 진행에 어려움이 있다.', value: null },
  { label: '사업고도화에 대한 전략을 가지고 있다.', value: null },
  { label: '전략을 수립할 때 데이터 분석을 기준으로 한다.', value: null },
  { label: '글로벌시장 진출을 목표로 하고 있다.', value: null },
  { label: '현재 매출이 하락하고 있어 다른 사업을 구상하고있다.', value: null },
  { label: '단계적 사업 구상과 마케팅 지표를 가지고 있다.', value: null },
  {
    label: '스타트업 기업의 경영 스타일이 나와 맞는다고 생각한다.',
    value: null,
  },
  { label: '사업 고도화가 매출보다 중요하다.', value: null },
  { label: '사업아이템을 평가 받는 멘토링을 받아본 적 있다.', value: null },
  { label: '정부지원 사업에 대한 기준을 명확하게 이해하고 있다.', value: null },
];

export default questions;
