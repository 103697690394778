import { ReactNode, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import AntModal, { ModalProps } from 'antd/lib/modal/Modal';
import clsx from 'clsx';
import qs from 'qs';

import Icon from '@web/shared/components/icon/Icon';

interface IProps extends ModalProps {
  modalID: string;
  children?: ReactNode;
  grey?: boolean;
  bodyNoPadding?: boolean;
  defaultOpen?: boolean;
  small?: boolean;
}
export default function Modal({
  modalID,
  defaultOpen,
  grey,
  bodyNoPadding,
  small,
  children,
  ...props
}: IProps) {
  const [open, setOpen] = useState(false);
  const location = useLocation();
  const history = useHistory();

  const handleClose = () => {
    history.goBack();
  };

  useEffect(() => {
    if (defaultOpen) {
      setOpen(true);
      return;
    }

    if (location.search) {
      const query = qs.parse(location.search, { ignoreQueryPrefix: true });

      if (query.modal === modalID) {
        setOpen(true);
      } else {
        setOpen(false);
      }
    } else {
      setOpen(false);
    }
  }, [location]);

  return (
    <AntModal
      visible={open}
      onCancel={handleClose}
      closeIcon={<Icon icon="close" />}
      width={1540}
      {...props}
      className={clsx(
        'client-modal',
        props.className,
        grey && 'grey',
        bodyNoPadding && 'bodyNoPadding',
        small && 'small',
      )}
    >
      {children}
    </AntModal>
  );
}
