import moment from 'moment';

import koreaTime from './koreaTime';

const isSameDay = (target: Date): boolean => {
  const today = moment(koreaTime).endOf('day');
  const targetDate = moment(target).endOf('day');

  return moment(today).isSame(targetDate);
};

export default isSameDay;
