import styled from 'styled-components';

import Icon from '@web/shared/components/icon/Icon';

export const HeaderDiv = styled.div`
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 99;
  transition: 0.2s;

  .rap {
    height: auto;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    .menu {
      width: 60%;
      display: flex;
      justify-content: center;
      position: relative;
      z-index: 2;

      > .item {
        text-align: center;
        padding: 2.6rem 2rem 0 2rem;
        position: relative;
        display: block;

        a {
          color: white;
          transition: 0s;
        }
      }

      .subMenu {
        height: 0;
        overflow: hidden;
        padding-bottom: 0;
        transition: 0.2s;

        &:before {
          content: '';
          display: block;
          margin: 0.8rem auto 0.4rem auto;
          width: 8px;
          height: 2px;
          background: #000;
        }

        a {
          font-size: 0.8rem;
          display: block;
          padding: 0.5rem 0;
          color: #000;
        }
      }
    }

    .logo,
    .util {
      padding-top: 1.5rem;
    }

    .util {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-top: 0.8rem;
      gap: 3rem;

      img {
        filter: invert(1);
      }
      .item {
        cursor: pointer;
        position: relative;
        width: 32px;
        & div {
          position: absolute;
          text-align: center;
          line-height: 1;
          font-size: 0.9rem;
          width: 60px;
          bottom: -14px;
          left: -14px;
        }
      }
    }
  }

  a {
    font-family: GmarketSansTTF;
    font-size: 1rem;
  }

  &.black {
    a div,
    .logo div {
      color: #000;
    }

    .util {
      img {
        filter: invert(0);
      }
    }
  }

  &.white_line .rap {
    position: relative;

    &::after {
      content: '';
      position: absolute;
      display: block;
      width: 100%;
      height: 1px;
      left: 0;
      top: 6.7rem;
      background: rgba(255, 255, 255, 0.5);

      @media (max-width: 1200px) {
        top: 8.7rem;
      }
    }

    .util .item div {
      color: white;
    }
  }

  @media (min-width: 1201px) {
    &:hover {
      background: #fff;
      box-shadow: 0 0 1rem rgba(0, 0, 0, 0.8);

      .logo {
        color: #000 !important;

        * {
          color: #000 !important;
          text-decoration: none !important;
        }
      }

      .menu {
        .subMenu {
          height: 100%;
          padding-bottom: 1.5rem;
        }

        a {
          color: #000 !important;
        }

        > .item {
          border-right: 1px solid #c1c1c1;

          &:nth-child(1) {
            border-left: 1px solid #c1c1c1;
          }
        }
      }

      .util {
        display: flex;
        justify-content: flex-end;

        img {
          filter: invert(0);
        }
      }

      &.white_line {
        .rap .util .item div {
          color: black;
        }
      }
    }
  }

  @media (max-width: 1200px) {
    .rap {
      align-items: center;
      padding-top: 2rem;
      padding-bottom: 2rem;

      .logo {
        width: 50%;
        padding-top: 0;
      }

      .menu {
        display: none !important;
      }

      .util {
        margin-top: 0;
        padding-top: 0;
      }
    }
  }
`;

export const MobileSideMenu = styled.div`
  font-family: GmarketSansTTF;
  position: fixed;
  width: 100%;
  height: 100%;
  right: 0;
  top: 0;
  z-index: 9999;

  .mento {
    cursor: pointer;
    text-decoration: underline;
    color: #7fffd4;
  }

  a {
    color: white;
  }

  .bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    z-index: 1;
  }

  .sideContent {
    float: right;
    height: 100%;
    display: inline-block;
    background: #4e36cc;
    position: relative;
    z-index: 2;
    box-shadow: 0 0 3rem rgba(0, 0, 0, 0.3);
    padding: 3rem 2rem 2rem 2.5rem;
    color: white;

    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 2.5rem;

      i {
        cursor: pointer;
      }

      .item {
        display: flex;
        align-items: center;
        cursor: pointer;

        i {
          margin-right: 0.6rem;
          font-size: 2.5rem;
        }
      }
    }

    .menuListBox {
      position: relative;

      .menuList {
        max-height: 78vh;
        overflow-y: auto;
        padding-bottom: 10vh;
        &:after {
          content: '';
          display: block;
          width: 100%;
          height: 30%;
          position: absolute;
          bottom: 0;
          left: 0;
          background: linear-gradient(to top, #4e36cc, rgba(78, 54, 204, 0));
          pointer-events: none;
        }

        .item {
          min-width: 40vw;
          margin-bottom: 2rem;

          .mainMenu {
            display: block;
            font-size: 1.4rem;
            border-bottom: 1px solid rgba(255, 255, 255, 0.15);
            padding-bottom: 0.1rem;
            margin-bottom: 0.3rem;

            &:hover div {
              color: #fff !important;
            }
          }

          .subMenu {
            padding-top: 0.2rem;
            padding-left: 1rem;

            .name {
              font-size: 1.2rem;
              padding: 0.3rem 0;
              display: block;
              opacity: 0.9;

              &:hover div {
                color: #fff !important;
              }
            }
          }
        }
      }
    }
  }

  @media (min-width: 1201px) {
    .sideContent {
      .menuListBox {
        .menuList {
          .item {
            min-width: 17rem;

            .mainMenu {
              margin-bottom: 0.35rem;
              font-size: 1.2rem;
            }

            .subMenu {
              .name {
                font-size: 0.9rem;
              }
            }
          }
        }
      }
    }
  }
`;

export const IconStyled = styled(Icon)`
  color: white;
`;

export const LogoStyle = styled.div`
  cursor: pointer;
  .logo {
    font-family: GmarketSansTTF;
    color: white;
    font-weight: 600;
    display: flex;
    align-items: center;
    white-space: nowrap;

    .mark {
      margin-right: 0.7rem;
      max-width: 3rem;
    }

    .name {
      font-size: 0.75rem;
      font-weight: 600;
    }

    .sub-name {
      font-size: 1.25rem;
      line-height: 1;
      padding: 0.1rem 0 0;
      text-decoration: none !important;
    }

    .en {
      font-family: 'Montserrat';
      font-weight: 500;
      font-size: 0.625rem;
      letter-spacing: 0.028rem;
    }
  }
`;
