import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { useFormik } from 'formik';
import * as yup from 'yup';

import Address from '@web/client/src/components/address/Address';
import NextPrevButton from '@web/client/src/components/signUp/NextPrevButton';
import { useActionAPI } from '@web/shared/apis';
import { Form, Input } from '@web/shared/components/form/Form';
import { getQS } from '@web/shared/utils';

import { IStep } from './Step1';

export default function Step2({ onCancel, myData, changeStep }: IStep) {
  const [address, setAddress] = useState(myData?.address ? myData.address : '');
  const [modal, setModal] = useState(false);
  const { action } = useActionAPI('users');

  const qs = getQS();
  const history = useHistory();
  const location = useLocation();

  const validationSchema = {
    // org: datas.org ? datas.org : null,
    address: yup.string().required('자택 주소를 입력해주세요.'),
  };

  const formik = useFormik({
    initialValues: {
      address: '',
      detailedAddress: '',
    },
    validationSchema: yup.object(validationSchema),

    onSubmit: async values => {
      const body = { ...values } as any;
      const mergedAddress = `${body.address}/${body.detailedAddress}`;
      await action(
        'put',
        {
          ...myData,
          address: mergedAddress,
          role: myData.role.type,
          step: myData.step < 2 ? 2 : myData.step,
          survey: myData.survey?.id || null,
          document: myData.document?.id || null,
        },
        Number(myData.id),
      );
      if (qs.steps) {
        history.push(`${location.pathname}?modal=UserAdd&steps=2`);
      }
    },
  });

  useEffect(() => {
    formik.setFieldValue('address', address);
  }, [address]);

  useEffect(() => {
    if (myData) {
      formik.setValues({
        address: myData.address?.split('/')[0],
        detailedAddress: myData.address ? myData.address?.split('/')[1] : '',
      });
    }
  }, [myData]);

  return (
    <>
      <div className="steps">
        <div className="header">
          <h1>2단계 인증 - 추가 정보</h1>
        </div>
        <div className="content">
          <Form formik={formik}>
            {/* <Select
              name="org"
              label="추천기관(선택)"
              nullLabel="없음"
              nullValue={null}
              options={[
                {
                  label: '기관1',
                  value: '기관1',
                },
                {
                  label: '기관2',
                  value: '기관2',
                },
              ]}
            /> */}
            <Input
              name="address"
              label="자택 주소"
              buttonText="주소 찾기"
              onButtonClick={() => {
                setModal(true);
              }}
              buttonWidth="8rem"
            />
            {formik.values.address && (
              <Input
                name="detailedAddress"
                label="상세 주소"
                buttonWidth="8rem"
              />
            )}
            <div className="description">
              <p>해당 단계를 작성하시면 2단계의 인증을 받으실 수 있습니다.</p>
              <p>상세 주소는 선택사항입니다.</p>
            </div>
          </Form>
        </div>

        <div className="buttonRap">
          <NextPrevButton onClick={() => changeStep(0)} prev text="이전 단계" />
          <NextPrevButton
            disabled={formik.isSubmitting}
            onClick={formik.submitForm}
            loading={formik.isSubmitting}
            text="업데이트"
            next
          />
        </div>
      </div>
      <Address
        visible={modal}
        onCancel={() => {
          setModal(false);
        }}
        close={() => setModal(false)}
        setData={v => setAddress(v)}
      />
    </>
  );
}
