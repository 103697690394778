import { ReactNode } from 'react';

import styled from 'styled-components';

import { IAnyObject } from '@web/shared/types/etc';

interface Types {
  children: ReactNode;
  rapProps?: IAnyObject;
  className?: string | undefined;
}

const RapDiv = styled.div`
  max-width: 1540px;
  width: 100%;
  margin: 0 auto;

  @media (max-width: 1600px) {
    padding-left: 2rem;
    padding-right: 2rem;
  }
`;

export default function Rap({ rapProps, className, children }: Types) {
  return (
    <RapDiv className={'rap ' + className} {...rapProps}>
      {children}
    </RapDiv>
  );
}
