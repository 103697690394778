import { useEffect, useState } from 'react';

import { FormWrapper } from './formStyle';

interface IEachInput {
  length: number;
  setInputValue: (v: string) => void;
  isError: boolean;
  setIsError: (v: boolean) => void;
}

export default function EachInput({
  length,
  setInputValue,
  isError,
  setIsError,
}: IEachInput) {
  const [isHandling, setIsHandling] = useState<boolean>(false);
  const [numberCodeForm, setNumberCodeForm] = useState(
    document.querySelector('[data-number-code-form]'),
  );

  useEffect(() => {
    setNumberCodeForm(document.querySelector('[data-number-code-form]'));
  }, []);

  useEffect(() => {
    const array: string[] = [];
    const notAllhasvalue = numberCodeInputs.some(v => {
      array.push(v.value);
      return !v.value;
    });
    setIsError(false);

    if (!notAllhasvalue) {
      setInputValue(array.join(''));
    }
  }, [isHandling]);

  const numberCodeInputs = [
    ...((numberCodeForm?.querySelectorAll('[data-number-code-input]') as any) ||
      []),
  ];

  const addHandling = () => {
    setIsHandling(false);
    setIsHandling(true);
  };

  const handleInput = ({ target }: any) => {
    if (!target.value.length) {
      return (target.value = null);
    }

    const inputLength = target.value.length;
    let currentIndex = Number(target.dataset.numberCodeInput);

    if (inputLength > 1) {
      const inputValues = target.value.split('');

      inputValues.forEach((value: any, valueIndex: any) => {
        const nextValueIndex = currentIndex + valueIndex;
        if (nextValueIndex >= numberCodeInputs.length) {
          return;
        }

        numberCodeInputs[nextValueIndex].value = value;
      });

      currentIndex += inputValues.length - 2;
    }
    const nextIndex = currentIndex + 1;

    if (nextIndex < numberCodeInputs.length) {
      numberCodeInputs[nextIndex].focus();
    }
    addHandling();
  };

  const handleKeyDown = (e: any) => {
    const { code, target } = e;

    const currentIndex = Number(target.dataset.numberCodeInput);
    const previousIndex = currentIndex - 1;
    const nextIndex = currentIndex + 1;

    const hasPreviousIndex = previousIndex >= 0;
    const hasNextIndex = nextIndex <= numberCodeInputs.length - 1;
    switch (code) {
      case 'ArrowLeft':
      case 'ArrowUp':
        if (hasPreviousIndex) {
          numberCodeInputs[previousIndex].focus();
        }
        e.preventDefault();
        break;

      case 'ArrowRight':
      case 'ArrowDown':
        if (hasNextIndex) {
          numberCodeInputs[nextIndex].focus();
        }
        e.preventDefault();
        break;
      case 'Backspace':
      case 'Delete':
        if (!e.target.value.length && hasPreviousIndex) {
          numberCodeInputs[previousIndex].value = null;
          numberCodeInputs[previousIndex].focus();
        }
        addHandling();
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    numberCodeForm?.addEventListener('input', handleInput);
    numberCodeForm?.addEventListener('keydown', handleKeyDown);

    return () => {
      numberCodeForm?.removeEventListener('input', handleInput);
      numberCodeForm?.removeEventListener('keydown', handleKeyDown);
    };
  }, [numberCodeForm]);

  return (
    <FormWrapper len={length} isError={isError}>
      <fieldset data-number-code-form>
        {Array(length)
          .fill(0)
          .map((_, i: number) => (
            <input
              onFocus={e => e.target.setSelectionRange(0, 2)}
              key={i}
              placeholder="•"
              data-number-code-input={`${i}`}
              required
              autoFocus={i === 0 ? true : false}
            />
          ))}
      </fieldset>
    </FormWrapper>
  );
}
