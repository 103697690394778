import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { LogoutOutlined, UserOutlined } from '@ant-design/icons';
import { Modal } from 'antd';

import isAfter from '@web/client/src/components/utils/isAfter';
import isProgress from '@web/client/src/components/utils/isProgress';
import isSameDay from '@web/client/src/components/utils/isSameDay';
import { useTrans } from '@web/client/src/hooks/useTrans';
import useAuth from '@web/shared/hooks/useAuth';
import { IMenu } from '@web/shared/templates';
import { IMentoring, IUser } from '@web/shared/types/strapi';

import Logo from './Logo';
import MainMenuList from './MainMenuList';
import SubMenuList from './SubMenuList';
import { MenuItemStyled } from './styled';

interface HeaderTypes {
  menus?: IMenu[] | undefined;
  data?: IUser;
}

export default function Header({ menus, data }: HeaderTypes) {
  const { pathname } = useLocation();

  const [active, setActive] = useState<IMenu | undefined>(undefined);
  const { signOut } = useAuth();
  const history = useHistory();

  const $ = useTrans();

  useEffect(() => {
    setActive(
      menus?.find(
        (v: any) =>
          v.link === pathname ||
          v.subMenu?.some((x: any) => x.link === pathname),
      ),
    );
  }, [pathname]);

  useEffect(() => {
    if (data?.role?.type === 'mento') {
      const mentorings = data?.mentoProfile?.mentorings;
      if (mentorings) {
        let progressMentoring: null | IMentoring = null;
        mentorings.forEach(item => {
          if (isProgress(new Date(), Number(item.time))) {
            progressMentoring = item;
            return;
          }
        });

        if (progressMentoring !== null) {
          Modal.confirm({
            title: `${$('멘토링 진행중')}`,
            content: `${$(
              '현재 진행중인 멘토링이 있습니다. 입장하시겠습니까?',
            )}`,
            onOk: () =>
              history.push(`/mentoring?addressId=${progressMentoring?.id}`),
            onCancel: () => {
              Modal.destroyAll();
            },
          });
          return;
        }

        const isAfterMentorings = mentorings.find(item => {
          const isAfterMentoring = isAfter(
            new Date(item?.date as string),
            Number(item.time),
          );

          const hasPeople = item.people?.length;

          const leavedComment = item.comments?.some(v => v.mento_profile);

          return hasPeople && isAfterMentoring && !leavedComment;
        });

        if (isAfterMentorings) {
          Modal.confirm({
            title: `${$('멘토링 완료')}`,
            content: `${$(
              '완료된 멘토링이 있습니다. 후기를 작성하러 가시겠습니까?',
            )}`,
            onOk: () =>
              history.push(`mentoring?reviewId=${isAfterMentorings?.id}`),
            onCancel: () => {
              Modal.destroyAll();
            },
          });
          return;
        }

        const hasTodayMentoring = mentorings.some(item => {
          const isAfterMentoring = isAfter(
            new Date(item?.date as string),
            Number(item.time),
          );

          const isSame = isSameDay(new Date(item.date as string));
          return isSame && !isAfterMentoring;
        });

        if (hasTodayMentoring) {
          Modal.confirm({
            title: `${$('멘토링 예정')}`,
            content: `${$(
              '금일 진행예정인 멘토링이 있습니다. 확인하시겠습니까?',
            )}`,
            onOk: () => history.push('mentoring'),
            onCancel: () => {
              Modal.destroyAll();
            },
          });
        }
      }
    }
  }, [data]);

  return (
    <>
      <header>
        <div className="left">
          <Logo />

          <MainMenuList
            menus={menus}
            active={active}
            onClickMenu={(v: IMenu | undefined) => {
              setActive(v);
            }}
          />
        </div>

        <div className="right">
          <MenuItemStyled className="logout">
            <UserOutlined />{' '}
            {data ? <span>{data?.name}</span> : <span>관리자</span>}
            {`${$('님')}`}
          </MenuItemStyled>

          <MenuItemStyled
            className="logout"
            onClick={async () => {
              await signOut();
              window.location.href = 'https://www.gef.kr/?status=logout';
            }}
          >
            <LogoutOutlined /> {`${$('로그아웃')}`}
          </MenuItemStyled>
        </div>
      </header>

      <SubMenuList active={active} />
    </>
  );
}
