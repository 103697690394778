import { ReactNode } from 'react';

import Footer from '@web/client/src/components/footer/Footer';
import Header from '@web/client/src/components/header/Header';
import ScrollButton from '@web/client/src/components/scroll/ScrollButton';

interface IProps {
  children?: ReactNode;
  theme?: string;
  removeScrollBtn?: boolean;
  removeFooter?: boolean;
}

export default function AppTemplate({
  children,
  theme,
  removeScrollBtn,
  removeFooter,
}: IProps) {
  return (
    <>
      <Header theme={theme} />

      {children}

      {!removeFooter && <Footer />}
      {!removeScrollBtn && <ScrollButton />}
    </>
  );
}
