import { useState } from 'react';
import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { Modal, notification } from 'antd';
import { AnimatePresence, motion } from 'framer-motion';

import Image from '@web/client/src/components/ui/Image';
import isProgress from '@web/client/src/components/utils/isProgress';
import isSameDay from '@web/client/src/components/utils/isSameDay';
import useModal from '@web/client/src/hooks/useModal';
import { useTrans } from '@web/client/src/hooks/useTrans';
import { useGetAPI } from '@web/shared/apis';
import { useGetMe } from '@web/shared/apis/users';
import Icon from '@web/shared/components/icon/Icon';
import useAuth from '@web/shared/hooks/useAuth';
import { IMentoProfile, IMentoring } from '@web/shared/types/strapi';
import { getQS } from '@web/shared/utils';

import menuIcon from '@web/client/src/assets/icons/menu.svg';
import userIcon from '@web/client/src/assets/icons/user.svg';

import Rap from '../contentRap/Rap';
import SignInModal from '../signIn/SignInModal';
import UserAdd from '../userInfo/UserAdd';
import isAfter from '../utils/isAfter';
import Logo from './Logo';
import { HeaderDiv, MobileSideMenu, IconStyled } from './headerStyle';
import { MenuList } from './menuList';
import { menus } from './menus';

interface IHeader {
  theme?: any;
}

export default function Header({ theme = 'black' }: IHeader) {
  const [menuOpen, setMenuOpen] = useState(false);
  const { getToken, signOut } = useAuth();
  const [isLoggedIn, setIsLoggedIn] = useState(getToken() ? true : false);

  const { data: myData } = useGetMe({ enabled: isLoggedIn });
  const isMento = isLoggedIn && Boolean(myData?.mentoProfile);
  const { data: mentoData } = useGetAPI<IMentoProfile>('mento-profiles', {
    id: Number(myData?.mentoProfile?.id),
    enabled: isMento,
  });

  const qs = getQS();
  const { open } = useModal();
  const history = useHistory();
  const location = useLocation();

  const $ = useTrans();
  const mentoPage = `https://www.mento.su-center.com/?token=${getToken()}`;

  useEffect(() => {
    if (location.search === '' && location.pathname === '/') {
      if (mentoData && !mentoData.introduction) {
        Modal.confirm({
          title: `${$('추가 정보 작성 가능')}`,
          content: `${$(
            '추가 정보를 작성하실 수 있습니다. 확인을 누르면 멘토페이지로 이동합니다.',
          )}`,
          okText: `${$('이동')}`,
          cancelText: `${$('취소')}`,
          onOk: () => {
            window.location.href = mentoPage;
            Modal.destroyAll();
          },
          onCancel: () => {
            Modal.destroyAll();
          },
        });
      }
    }

    if (mentoData?.mentorings && location.search === '') {
      let progressMentoring: null | IMentoring = null;
      mentoData.mentorings.forEach(item => {
        if (isProgress(new Date(), Number(item.time))) {
          progressMentoring = item;
          return;
        }
      });

      if (progressMentoring !== null) {
        Modal.confirm({
          title: '멘토링 진행중',
          content:
            '현재 진행중인 멘토링이 있습니다. 입장하시겠습니까? 멘토링 진행 방식에 따라 입장해주세요.',
          onOk: () =>
            (window.location.href = `https://www.mento.su-center.com/mentoring?addressId=${progressMentoring?.id}`),
          onCancel: () => {
            Modal.destroyAll();
          },
        });
        return;
      }

      const isAfterMentorings = mentoData.mentorings.find(item => {
        const isAfterMentoring = isAfter(
          new Date(item?.date as string),
          Number(item.time),
        );

        const leavedComment = item.comments?.some(
          v => Number(v.user) === mentoData.id && !v.mento_profile,
        );

        return isAfterMentoring && !leavedComment;
      });

      if (isAfterMentorings) {
        Modal.confirm({
          title: '멘토링 완료',
          content: '완료된 멘토링이 있습니다. 후기를 작성하러 가시겠습니까?',
          onOk: () =>
            (window.location.href = `https://www.mento.su-center.com/mentoring?reviewId=${isAfterMentorings?.id}`),
          onCancel: () => {
            Modal.destroyAll();
          },
        });
        return;
      }

      const hasTodayMentoring = mentoData.mentorings.some(item => {
        const isAfterMentoring = isAfter(
          new Date(item?.date as string),
          Number(item.time),
        );

        const isSame = isSameDay(new Date(item.date as string));
        return isSame && !isAfterMentoring;
      });

      if (hasTodayMentoring) {
        Modal.confirm({
          title: '멘토링 예정',
          content: '금일 진행예정인 멘토링이 있습니다. 확인하시겠습니까?',
          onOk: () =>
            (window.location.href =
              'https://www.mento.su-center.com/mentoring'),
          onCancel: () => {
            Modal.destroyAll();
          },
        });
      }
    }
  }, [mentoData]);

  useEffect(() => {
    if (myData) {
      if (myData.username === 'partnersaccount') {
        // Modal.confirm({
        //   title: '파트너 전용 계정',
        //   content:
        //     '파트너 전용 계정으로 로그인하셨습니다. 전용 페이지로 이동하시겠습니까?',
        //   onOk: () => {
        //     Modal.destroyAll();
        //     history.push('/partners');
        //   },
        //   onCancel: () => {
        //     Modal.destroyAll();
        //   },
        // });
        history.push('/partners');

        return;
      }
    }
    if (location.search === '' && location.pathname === '/') {
      if ((myData?.step && myData.step !== 3) === 0) {
        Modal.confirm({
          title: `${$('추가 정보 요청')}`,
          content: `${$(
            '추가 정보를 기입하시겠습니까? 추가 정보를 입력하시면 컨설팅 혹은 멘토링에 더 많은 도움이 됩니다.',
          )}`,
          okText: `${$('정보 입력')}`,
          onOk: () => {
            Modal.destroyAll();
            history.push('/?modal=UserAdd&steps=0');
          },
          onCancel: () => {
            Modal.destroyAll();
          },
          cancelText: `${$('취소')}`,
        });
      }
    }

    if (myData?.mentorings && location.search === '') {
      const progressMentoring = myData.mentorings.find(item =>
        isProgress(new Date(), Number(item.time)),
      );

      if (progressMentoring) {
        Modal.confirm({
          title: '멘토링 진행중',
          content: '현재 진행중인 멘토링이 있습니다. 입장하시겠습니까?',
          onOk: () =>
            history.push(
              `/my-page?modal=MentoApply&addressId=${progressMentoring?.id}`,
            ),
          onCancel: () => {
            Modal.destroyAll();
          },
        });
        return;
      }

      const isAfterMentorings = myData.mentorings.find(item => {
        const isAfterMentoring = isAfter(
          new Date(item?.date as string),
          Number(item.time),
        );

        const leavedComment = item.comments?.some(
          v => Number(v.user) === myData.id && !v.mento_profile,
        );

        return isAfterMentoring && !leavedComment;
      });

      if (isAfterMentorings) {
        Modal.confirm({
          title: '멘토링 완료',
          content:
            '완료된 멘토링이 있습니다. 후기를 작성하고 피드백을 확인하시겠습니까?',
          onOk: () =>
            history.push(
              `/my-page?modal=MentoApply&reviewid=${isAfterMentorings?.id}`,
            ),
          onCancel: () => {
            Modal.destroyAll();
          },
        });
        return;
      }

      const hasTodayMentoring = myData.mentorings.some(item => {
        const isAfterMentoring = isAfter(
          new Date(item?.date as string),
          Number(item.time),
        );

        const isSame = isSameDay(new Date(item.date as string));
        return isSame && !isAfterMentoring;
      });

      if (hasTodayMentoring) {
        Modal.confirm({
          title: '멘토링 예정',
          content: '금일 진행예정인 멘토링이 있습니다. 확인하시겠습니까?',
          onOk: () => history.push('/my-page?modal=MentoApply'),
          onCancel: () => {
            Modal.destroyAll();
          },
        });
      }
    }
  }, [myData]);

  useEffect(() => {
    const elm: any = document.querySelector('html');

    if (menuOpen) {
      elm.style.overflow = 'hidden';
    } else {
      elm.style.overflow = 'auto';
    }
  }, [menuOpen]);

  useEffect(() => {
    if (getToken()) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  }, [getToken()]);

  useEffect(() => {
    if (qs.status === 'logout') {
      onClickLogOut();
    }
  }, [qs]);

  const handleSubmit = () => {
    notification.success({
      message: `${$('로그아웃이 완료되었습니다')}`,
      duration: 5,
      placement: 'topLeft',
    });

    history.push('/');
  };

  const onClickLogOut = async () => {
    await signOut();
    setMenuOpen(false);
    handleSubmit();
  };

  const popMentoModal = () => {
    Modal.confirm({
      title: `${$('페이지 이동')}`,
      content: `${$('확인을 누르시면 멘토 마이페이지로 이동합니다.')}`,
      onOk: () => (window.location.href = mentoPage),
      okText: 'OK',
    });
  };

  const onClickLogin = async () => {
    if (!isLoggedIn) {
      open('SignIn');
    } else {
      if (isMento) {
        popMentoModal();
      } else {
        history.push('/my-page');
      }
    }
    setMenuOpen(false);
  };

  return (
    <>
      <HeaderDiv className={`UI-header ${theme}`}>
        <Rap>
          <Logo />

          <MenuList menus={menus} className="menu" />

          <div className="util">
            <div className="item" onClick={onClickLogin}>
              <div>{isLoggedIn ? 'My Page' : 'Login'}</div>
              <Image src={userIcon} alt="user-icon" />
            </div>

            <div className="item" onClick={() => setMenuOpen(true)}>
              <Image src={menuIcon} alt="menu-icon" />
            </div>
          </div>
        </Rap>
      </HeaderDiv>

      <SignInModal />

      <AnimatePresence>
        {menuOpen && (
          <>
            <MobileSideMenu>
              <motion.div
                className="bg"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.2 }}
                onClick={() => setMenuOpen(false)}
              ></motion.div>

              <motion.div
                className="sideContent"
                initial={{ x: '100%', opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                exit={{ x: '100%', opacity: 0 }}
                transition={{ duration: 0.2 }}
              >
                <div className="header">
                  <div className="item" onClick={onClickLogin}>
                    <Icon icon="account_circle" />
                    {!isLoggedIn ? `${$('로그인')}` : `${$('내 정보')}`}
                  </div>

                  <IconStyled
                    icon="arrow_forward"
                    onClick={() => setMenuOpen(false)}
                  />
                </div>

                {mentoData && (
                  <a className="mento" href={mentoPage}>
                    {`${$('멘토 전용페이지로 이동하기')}`}
                  </a>
                )}

                <div className="menuListBox">
                  <MenuList
                    menus={menus}
                    className="menuList"
                    onClick={() => {
                      setMenuOpen(false);
                    }}
                  />
                </div>
                {isLoggedIn && (
                  <div className="header">
                    <div className="item" onClick={onClickLogOut}>
                      <Icon icon="logout" />
                      {`${$('로그아웃')}`}
                    </div>
                  </div>
                )}
              </motion.div>
            </MobileSideMenu>
          </>
        )}
      </AnimatePresence>

      {isLoggedIn && !mentoData && <UserAdd myData={myData} />}
    </>
  );
}
