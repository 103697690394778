import { Link } from 'react-router-dom';

import { IAnyObject } from '@web/shared/types/etc';

interface IMenuList {
  menus: IAnyObject;
  className: string;
  onClick?: () => void;
}

export function MenuList({ menus, className, onClick }: IMenuList) {
  return (
    <div className={className}>
      {menus.map((v: any, i: number) => (
        <div className="item" key={i}>
          <Link
            to={v.link}
            className="mainMenu name"
            onClick={() => onClick && onClick()}
          >
            <div>{v.name}</div>
          </Link>

          <div className="subMenu">
            {v.subMenus.map((x: any, c: number) => (
              <Link
                to={x.link}
                className="name"
                key={c}
                onClick={() => onClick && onClick()}
              >
                <div>{x.name}</div>
              </Link>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
}
