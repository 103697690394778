import { ReactNode, useContext } from 'react';

import { Select as AntSelect } from 'antd';
import { FormikContext } from 'formik';

import { FormField } from '@web/shared/components/form/Form';

interface OptionType {
  label: string;
  value: string;
}

interface SelectTypes {
  name: string;
  label?: string;
  helperText?: string;
  options: Array<OptionType>;
  nullValue?: any;
  nullLabel?: ReactNode;
  onClickItem?: (data: any) => void;
}

export default function Select({
  name,
  label,
  options,
  helperText,
  nullValue = '',
  nullLabel = '선택',
  onClickItem,
}: SelectTypes) {
  const formik = useContext(FormikContext);
  const error = formik.errors[name];
  const onChange = (value: string) => {
    formik.setFieldValue(name, value);
  };

  return (
    <FormField
      label={label}
      error={error ? helperText || (error as string) : ''}
    >
      <AntSelect
        style={{ width: '100%' }}
        value={!onClickItem ? formik.values[name] : ''}
        onChange={!onClickItem ? onChange : () => undefined}
        getPopupContainer={trigger => trigger.parentNode}
      >
        <AntSelect.Option value={nullValue}>{nullLabel}</AntSelect.Option>

        {options?.map(v => (
          <AntSelect.Option key={v.value} value={v.value}>
            <div
              onClick={() => {
                onClickItem?.(v);
              }}
            >
              {v.label}
            </div>
          </AntSelect.Option>
        ))}
      </AntSelect>
    </FormField>
  );
}
