import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { ModalProps, notification, Modal as AntModal } from 'antd';

import { IAnyObject } from '@web/shared/types/etc';
import { getQS } from '@web/shared/utils';

import ProgressBar from './ProgressBar';
import Step1 from './steps/Step1';
import Step2 from './steps/Step2';
import Step3 from './steps/Step3';
import { ModalStyled } from './style';

interface IUserAdd extends ModalProps {
  myData: IAnyObject;
}

export default function UserAdd({ myData, ...props }: IUserAdd) {
  const qs = getQS();
  const [now, setNow] = useState<number>(0);
  const history = useHistory();
  const location = useLocation();

  const steps = [Step1, Step2, Step3];
  const StepCard = steps[now];

  useEffect(() => {
    if (qs.steps) {
      setNow(Number(qs.steps));
    }
  }, [qs]);

  const onClose = () => {
    history.push(location.pathname);
    notification.success({
      message: `${myData.step}단계까지 인증을 진행하셨습니다. 차후에 전용 페이지에서 다시 업데이트 하실 수 있습니다.`,
    });
  };
  const changeStep = (i: number) => {
    AntModal.confirm({
      title: '확인 메시지',
      content:
        '페이지를 이동하시면 작성중인 정보가 사라집니다. 페이지를 이동하시겠습니까?',

      onOk: () => {
        history.push(`${location.pathname}?modal=UserAdd&steps=${i}`);
      },
    });
  };

  return (
    <>
      <ModalStyled
        modalID="UserAdd"
        width={675}
        centered
        destroyOnClose
        footer={false}
        onCancel={onClose}
        {...props}
      >
        <ProgressBar now={now} step={myData?.step} />
        {StepCard && (
          <StepCard
            myData={myData}
            changeStep={(v: number) => changeStep(v)}
            onCancel={onClose}
          />
        )}
      </ModalStyled>
    </>
  );
}
