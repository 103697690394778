import { IMenu } from '@web/shared/templates';
import { MangeSubHeaderStyled } from '@web/shared/templates/styled';

import MenuItem from './MenuItem';

interface SubMenuListTypes {
  active: IMenu | undefined;
}

export default function SubMenuList({ active }: SubMenuListTypes) {
  return (
    <MangeSubHeaderStyled>
      {active && (
        <div className="menulist">
          {active.subMenu?.map((v, key) => (
            <MenuItem name={v.name} link={v.link} key={key} />
          ))}
        </div>
      )}
    </MangeSubHeaderStyled>
  );
}
