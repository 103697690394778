import moment from 'moment';

const isAfter = (date: Date, minutes: number) => {
  const beforeTime = moment(date);
  const afterTime = beforeTime.add(minutes, 'minutes');

  return moment().isAfter(afterTime);
};

export default isAfter;
