import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import qs from 'qs';

import URouteSwitch from '@web/shared/routes/URouteSwitch';

import './app.scss';
import routes from './config/route';
import defaultTemplateProps from './config/template';

import 'antd/dist/antd.css';

function App() {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    const query = qs.parse(location.search, { ignoreQueryPrefix: true });
    const elm: any = document.querySelector('html');

    if (query.modal) {
      elm.style.overflow = 'hidden';
    } else {
      elm.style.overflow = 'auto';
    }
  }, [location]);

  return (
    <>
      <URouteSwitch
        routes={routes}
        dispatch={dispatch}
        history={history}
        templateProps={defaultTemplateProps}
      />
    </>
  );
}
export default App;
