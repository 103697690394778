import { memo, ReactNode } from 'react';

import { IMenu } from '.';
import { useGetMe } from '../apis/users';
import Header from '../components/header/Header';
import { MangeTemplateStyled } from './styled';

export interface IAdminTemplateProps {
  children?: ReactNode;
  menus?: IMenu[] | undefined;
  contentSize?: number;
  theme?: string;
  removeScrollBtn?: boolean;
  error?: string;
  removeFooter?: boolean;
}

export default memo(function AdminTemplate({
  children,
  menus,
  contentSize,
}: IAdminTemplateProps) {
  const { data } = useGetMe();

  return (
    <MangeTemplateStyled maxWidth={contentSize && contentSize}>
      <Header menus={menus} data={data} />

      <div className="UI-content">{children}</div>
    </MangeTemplateStyled>
  );
});
