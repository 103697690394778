import newLogo from '@web/shared/assets/icons/newLogo.png';

export default function Logo() {
  return (
    <div
      onClick={() => (window.location.href = 'https://www.gef.kr/')}
      className="logo"
    >
      <img src={newLogo} alt="logo" />
    </div>
  );
}
