import { Dispatch, memo, useState } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';

import { AnimatePresence, motion } from 'framer-motion';
import { History } from 'history';

import { IRoute } from '.';
import templates, { ITemplateProps, TTemplateKey } from '../templates';
import _defaultTemplateProps from '../templates/defaultProps';
import URoute from './URoute';

interface IProps {
  routes: IRoute[];
  dispatch: Dispatch<any>;
  history: History<unknown>;
  templateProps?: Record<TTemplateKey, ITemplateProps>;
}

const variants = {
  init: {
    opacity: 0,
  },

  in: {
    opacity: 1,
  },

  out: {
    opacity: 0,
  },
};

export default memo(function URouteSwitch({
  routes,
  dispatch,
  history,
  templateProps: initTemplateProps,
}: IProps) {
  const location = useLocation();
  const [templateProps, setTemplateProps] = useState(
    initTemplateProps || _defaultTemplateProps,
  );
  const templateGroupRoutes = routes.reduce(
    (acc, route) => {
      route.template = route.template || 'Default';

      if (acc[route.template]) {
        acc[route.template] = [...acc[route.template], route];
      } else {
        acc[route.template] = [route];
      }

      return acc;
    },
    {} as {
      [key in TTemplateKey]: IRoute[];
    },
  );

  const onChangePage = (
    templateName: TTemplateKey,
    templateProps?: ITemplateProps,
  ) => {
    const _initTemplateProps = initTemplateProps || _defaultTemplateProps;

    setTemplateProps({
      ..._initTemplateProps,
      [templateName]: {
        ..._initTemplateProps[templateName],
        ...templateProps,
      },
    });
  };

  return (
    <AnimatePresence>
      <Switch>
        {Object.entries(templateGroupRoutes).map(([key, groupRoutes]) => {
          const TemplateComponent = templates[key as TTemplateKey];
          const templatePaths = groupRoutes.map(route => route.path);
          return (
            <Route key={key} path={templatePaths} exact>
              <motion.div
                className="templateMotion"
                initial="init"
                animate="in"
                exit="out"
                variants={variants}
                transition={{ duration: 0.3 }}
              >
                <TemplateComponent {...templateProps[key as TTemplateKey]}>
                  <AnimatePresence initial={false}>
                    <Switch location={location} key={location.pathname}>
                      {groupRoutes.map(route => {
                        return (
                          <Route key={route.path} path={route.path} exact>
                            <URoute
                              {...route}
                              dispatch={dispatch}
                              history={history}
                              onChangePage={onChangePage}
                            />
                          </Route>
                        );
                      })}
                    </Switch>
                  </AnimatePresence>
                </TemplateComponent>
              </motion.div>
            </Route>
          );
        })}
      </Switch>
    </AnimatePresence>
  );
});
