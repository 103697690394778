import { useContext } from 'react';

import { Radio as AntRadio } from 'antd';
import { FormikContext } from 'formik';
import styled from 'styled-components';

import { FormField } from '@web/shared/components/form/Form';
import { IAnyObject } from '@web/shared/types/etc';

interface OptionWrapperProps {
  styleObj: IAnyObject;
}

const OptionWrapper = styled.div<OptionWrapperProps>`
  ${props => props.styleObj}
`;

interface RadioTypes {
  name: string;
  label?: string;
  helperText?: string;
  options: Array<any>;
}

export default function Radio({
  name,
  label,
  helperText,
  options,
}: RadioTypes) {
  const formik = useContext(FormikContext);
  const error = formik.errors[name];

  const onChange = (e: any) => {
    formik.setFieldValue(name, e.target.value);
  };

  return (
    <FormField
      label={label}
      error={error ? helperText || (error as string) : ''}
    >
      <AntRadio.Group
        name={name}
        value={formik.values[name] || options[0].value}
        onChange={onChange}
      >
        {options.map(v => (
          <OptionWrapper
            key={v.value}
            styleObj={v?.style ? v.style : {}}
            className="radioBox"
          >
            <AntRadio value={v.value}>{v.label}</AntRadio>
            {v?.children && v.children}
          </OptionWrapper>
        ))}
      </AntRadio.Group>
    </FormField>
  );
}
