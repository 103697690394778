import { ITemplateProps, TTemplateKey } from '@web/shared/templates';
import { IAdminTemplateProps } from '@web/shared/templates/AdminTemplate';

export const adminTemplateProps: IAdminTemplateProps = {
  menus: [
    {
      name: '네트워크',
      link: '/partners',
      subMenu: [
        {
          name: '목록',
          link: '/partners',
        },
        {
          name: '작성하기',
          link: '/partners?formModal=partners',
        },
      ],
    },
    {
      name: '작성자 정보',
      link: '/partnerusers',
      subMenu: [
        {
          name: '목록',
          link: '/partnerusers',
        },
        {
          name: '작성하기',
          link: '/partnerusers?formModal=partnerusers',
        },
      ],
    },
  ],
};

const defaultTemplateProps = {
  Admin: adminTemplateProps,
} as Record<TTemplateKey, ITemplateProps>;

export default defaultTemplateProps;
