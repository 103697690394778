import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type TSignUpState = {
  data: any;
  step: number;
};

const initialState: TSignUpState = {
  data: {},
  step: 1,
};

const signUpSlice = createSlice({
  name: 'signUp',
  initialState,
  reducers: {
    next(state) {
      state.step += 1;
    },

    prev(state) {
      state.step -= 1;
      if (state.step < 1) {
        state.step = 1;
      }
    },

    setStep(state, action: PayloadAction<number>) {
      state.step = action.payload;
    },

    setData(state, action) {
      state.data = { ...state.data, ...action.payload };
    },

    clearData(state) {
      state.data = {};
    },
  },
});

export const { next, prev, setStep, setData, clearData } = signUpSlice.actions;
export default signUpSlice.reducer;
