import { useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

import { Button, ModalProps, Modal as AntModal } from 'antd';
import { useFormik } from 'formik';
import * as yup from 'yup';

import Modal from '@web/client/src/components/ui/Modal';
import { checkPassword } from '@web/client/src/components/utils/util';
import useModal from '@web/client/src/hooks/useModal';
import { useActionAPI, useGetAPI } from '@web/shared/apis';
import { Form, Input } from '@web/shared/components/form/Form';

import { PasswordStyled } from './style';

interface IPasswordModal extends ModalProps {
  myID: number | undefined;
}

export default function PasswordModal({ myID, ...props }: IPasswordModal) {
  const [clicked, setClicked] = useState(false);
  const { action } = useActionAPI('users');
  const { data: myData } = useGetAPI('users', {
    enabled: Boolean(myID),
    id: myID,
  });

  const { open } = useModal();
  const history = useHistory();
  const location = useLocation();

  const isLocal = myData?.provider === 'local';

  const validationSchema = {
    password: yup.string().required('비밀번호를 입력해주세요'),
    repassword: yup
      .string()
      .required('확인 비밀번호를 입력해주세요')
      .oneOf([yup.ref('password'), null], '비밀번호가 상이합니다.'),
  };
  const formik = useFormik({
    initialValues: {
      password: '',
      repassword: '',
    },
    validationSchema: yup.object(validationSchema),

    onSubmit: async values => {
      const isVaild = checkPassword(values.password);
      if (!isVaild) {
        return;
      }
      const dataObj = {
        ...myData,
        password: values.password,
        role: myData.role.type,
        survey: myData.survey?.id || null,
        document: myData.document?.id || null,
      };

      if (myData.mentoProfile) {
        dataObj.mentoProfile = myData.mentoProfile.id;
      }

      await action('put', dataObj, myData.id);

      history.push(location.pathname);
      AntModal.error({
        title: '비밀번호 변경 완료',
        content:
          '변경된 비밀번호로 로그인해주세요. 확인을 누르면 로그인창으로 이동합니다.',
        onOk: () => {
          AntModal.destroyAll();
          open('SignIn');
        },
      });
    },
  });

  return (
    <>
      <Modal
        modalID="Password"
        title={!clicked ? '아이디 찾기' : '비밀번호 변경하기'}
        small
        centered
        destroyOnClose
        {...props}
      >
        <PasswordStyled>
          <div className="idWrapper">
            <div>ID</div>
            <div className="id">
              {!isLocal ? (
                <>
                  <p>{`${myData?.provider}과 연동된 계정입니다.`}</p>
                  <p>{`EMAIL : ${myData?.email}`}</p>
                </>
              ) : (
                myData?.username
              )}
            </div>
            {!clicked && isLocal && (
              <Button
                className="passwordButton"
                onClick={() => setClicked(true)}
              >
                비밀번호 변경하기
              </Button>
            )}
          </div>
          {clicked && (
            <Form formik={formik}>
              <Input
                type="password"
                placeholder="영문, 숫자, 특수문자 포함 8자 이상"
                name="password"
                label="Password"
                autoComplete="on"
              />
              <Input
                type="password"
                placeholder="영문, 숫자, 특수문자 포함 8자 이상"
                name="repassword"
                label="Confirm Password"
                autoComplete="on"
              />
            </Form>
          )}
          <Button
            loading={formik.isSubmitting}
            onClick={
              !clicked
                ? () => {
                    history.push(location.pathname);
                    open('SignIn');
                  }
                : () => formik.submitForm()
            }
            type="primary"
            block
          >
            {!clicked ? '로그인하러 가기' : '비밀번호 변경하기'}
          </Button>
        </PasswordStyled>
      </Modal>
    </>
  );
}
