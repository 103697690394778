import { Modal } from 'antd';

const SERVICE_KEY =
  'GROAlfQ1PomixQ7AmFjjihyqnDjjiKtRpszRVbYhSPeUTqr4X1PdEdlm5voHB6cs76LMECeMX%2Bme9YUwP5xIsg%3D%3D';
const requestUrl = `http://api.odcloud.kr/api/nts-businessman/v1/validate?serviceKey=${SERVICE_KEY}&returnType=JSON`;

const validConfirm = async (data: any, firstItem: any, secondItem: any) => {
  const isValidData = async () => {
    const requestHeaders: HeadersInit = new Headers();
    requestHeaders.set('Content-length', '298');
    requestHeaders.set('content-type', 'application/json; charset=UTF-8');
    const res = await fetch(requestUrl, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: requestHeaders,
    });
    const json = await res.json();
    return json;
  };

  const dataValidation = await isValidData();

  if (dataValidation.data) {
    if (dataValidation.data[0].valid_msg === '확인할 수 없습니다.') {
      Modal.error({
        title: '확인 불가',
        content:
          '사업자를 확인할 수 없습니다. 정확하게 기입하였는지 확인해주세요.',
      });
      return false;
    }
  } else if (
    dataValidation.status_code === 'REQUEST_DATA_MALFORMED' &&
    (firstItem || secondItem)
  ) {
    Modal.error({
      title: '항목 누락',
      content:
        '사업자 번호, 대표 명 중 하나 이상이 누락되었습니다. 확인해주세요.',
    });
    return false;
  } else if (dataValidation.code === -4) {
    Modal.error({
      title: 'API 만료',
      content: 'API가 만료되었습니다. 관리자에게 문의해주세요.',
    });
    return false;
  }
  return true;
};

export default validConfirm;
