import styled from 'styled-components';

import Modal from '@web/client/src/components/ui/Modal';
import { Input } from '@web/shared/components/form/Form';

export const QuestionsStyled = styled.div`
  .item {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 550px) {
      flex-direction: column;
      align-items: flex-start;
    }

    > .Questions-label {
      font-family: GmarketSansTTF;
      font-size: 12px;
      font-weight: 500;
      color: #7c8393;

      @media (max-width: 550px) {
        margin-bottom: 1rem;
      }
    }

    & + .item {
      margin-top: 22px;
    }

    .ant-select.small {
      width: 9rem;

      @media (max-width: 600px) {
        width: 10rem;
      }

      @media (max-width: 550px) {
        width: 100%;
      }
    }
  }
`;

export const ModalStyled = styled(Modal)`
  .ant-modal-body {
    padding: 2.5rem !important;
    max-height: 100vh !important;
  }

  .steps {
    max-width: 600px;
    width: 100%;
    background-color: white;
    border-radius: 30px;
    box-shadow: 0px 35px 68px 0 rgba(140, 140, 199, 0.32);
    padding: 3.125rem;
    max-height: 70vh;
    padding-right: calc(1.5rem - 8px);

    .header {
      display: flex;
      justify-content: space-between;
      padding-right: 1.625rem;
      margin-bottom: 1.5rem;
      h1 {
        font-family: GmarketSansTTF;
        font-weight: 500;
        color: black;
        margin-bottom: 0.15rem;
        @media (max-width: 500px) {
          font-size: 2.5rem;
        }
      }

      .close {
        img {
          cursor: pointer;
        }
      }
    }

    .content {
      max-height: 42vh;
      overflow-y: auto;
      padding-bottom: 2rem;
      padding-right: 1.625rem;
      .idWrapper {
        display: flex;
        justify-content: space-between;
        .hyphen {
          line-height: 2.2;
          font-size: 3rem;
          font-weight: 500;
          @media (max-width: 700px) {
            & {
              line-height: 3.2;
            }
          }
        }
      }
    }

    .buttonRap {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-right: 1.625rem;
    }

    .ant-upload {
      width: 100%;

      .shared-formField {
        margin-bottom: 0;
      }
    }

    .ant-upload-list-item {
      border: none;
    }

    .ant-upload-list-item-name {
      font-family: GmarketSansTTF;
      font-size: 0.813rem;
      font-weight: 500;
      color: #7c8393;
      margin-bottom: -2px;
    }

    .ant-upload-text-icon {
      img {
        display: block;
      }
    }

    .ant-input-textarea-show-count::after {
      content: attr(data-count2);
    }
    .description {
      & p {
        margin: 0;
      }
      opacity: 0.7;
      color: #4e36cc;
      margin-top: 1.5rem;
    }
  }
`;

export const ProgressBarStyled = styled.ul`
  display: flex;
  justify-content: space-between;
  list-style: none;
  padding: 0;
  margin: 0 0 2rem 0;
  & li:first-child {
    text-align: left;
    flex: 1;
  }
  & li:last-child {
    flex: 1;
    text-align: right;
  }
  & li:first-child div {
    left: 0;
  }
  & li:last-child div {
    right: 0;
    left: auto;
  }
  & li:not(.is-active) span {
    opacity: 0;
  }
  & .is-complete:not(:first-child):after,
  & .is-active:not(:first-child):after {
    content: '';
    display: block;
    width: 100%;
    position: absolute;
    bottom: -2px;
    left: -50%;
    z-index: 2;
    border-bottom: 2px solid #cc0001;
  }
  & li:last-child span {
    width: 200%;
    display: inline-block;
    position: absolute;
    left: -100%;
  }
  & .is-complete:last-child:after,
  & .is-active:last-child:after {
    width: 200%;
    left: -100%;
  }
  & .is-complete div {
    background-color: #cc0001;
  }
  & .is-active div,
  & div:hover {
    background-color: #fff;
    border-color: #cc0001;
  }
  &:hover li:not(:hover) span {
    opacity: 0;
  }
`;

export const ListStyled = styled.li`
  flex: 2;
  position: relative;
  padding: 0 0 1rem 0;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #cc0001;
  font-weight: 600;
  white-space: nowrap;
  overflow: visible;
  min-width: 0;
  text-align: center;
  border-bottom: 2px solid #313131;
  & div {
    content: '';
    display: block;
    width: 12px;
    height: 12px;
    background-color: #313131;
    border-radius: 50%;
    border: 2px solid #fff;
    position: absolute;
    left: calc(50% - 6px);
    bottom: -7px;
    z-index: 3;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
  }
  & span {
    transition: opacity 0.3s ease-in-out;
  }
  & div:hover {
    transform: scale(1.33);
  }
  & div:hover + span {
    opacity: 1;
  }

  @media screen and (max-width: 640px) {
    white-space: normal;
  }
`;

export const InputStyled = styled(Input)`
  .ant-input-group-addon {
    overflow: visible !important;
    border: 0 !important;
  }
  .ant-select-selector {
    width: 11rem !important;
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
`;
