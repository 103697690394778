import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import styled from 'styled-components';

const LoadingStyled = styled(LoadingOutlined)`
  font-size: 24;
`;

export default function SpinLoading() {
  const antIcon = <LoadingStyled spin />;

  return <Spin indicator={antIcon} />;
}
