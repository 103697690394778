import styled from 'styled-components';

interface Props {
  maxWidth?: number | undefined;
}

export const MangeTemplateStyled = styled.div<Props>`
  .UI-content {
    position: relative;
    max-width: ${props => (props.maxWidth ? props.maxWidth : '100%')};
    margin: 0 auto;

    .sharedPageMotion {
      padding: 0 2.75rem;
    }
  }

  header {
    padding: 0 1.5rem;
    box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.05);
    display: flex;
    justify-content: space-between;
    position: fixed;
    z-index: 11;
    width: 100%;
    top: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.9);
    backdrop-filter: saturate(180%) blur(20px);

    .left {
      display: flex;

      .menu-list {
        display: flex;
      }

      .active > div {
        color: #000;
        font-weight: bold;
      }

      .logo {
        height: 4rem;
        line-height: 4rem;
        padding: 0 1.25rem;
        cursor: pointer;

        img {
          max-height: 60%;
        }
      }
    }

    .right {
      display: flex;

      .anticon-user {
        width: 1.4rem;
        height: 1.4rem;
        border-radius: 50%;
        background: #4e36cc;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
      }

      .anticon-logout {
        position: relative;
        top: 1px;
      }

      > div {
        transition: 0.1s;
        color: #444;

        span {
          font-weight: bold;
          margin-right: 2px;
        }

        .anticon {
          margin-right: 0.4rem;
        }

        &:hover {
          color: #4e36cc;
        }
      }
    }
  }

  .menuActiveLine {
    width: 100%;
    height: 2px;
    position: absolute;
    background: #4e36cc;
    bottom: 0;
  }
`;

export const MangeSubHeaderStyled = styled.div`
  padding: 1.5rem 2rem 1rem 2.75rem;
  @media (max-width: 800px) {
    padding: 0.5rem 2rem 3rem 2.75rem;
  }
  margin-top: 64px;

  .menulist {
    display: flex;
    @media (max-width: 800px) {
      justify-content: space-between;
    }

    > div {
      font-size: 27px;
      font-weight: 700;
      padding-left: 0;
      opacity: 0.2;
      cursor: pointer;
      color: #222;
      padding-right: 0.8rem;
      letter-spacing: -1px;
      transition: 0.1s;
      @media (max-width: 800px) {
        font-size: 23px;

        width: 30%;
      }
      @media (max-width: 500px) {
        font-size: 15px;
      }

      &:hover {
        opacity: 0.5;
      }

      &.active {
        opacity: 1;
      }
    }
  }
`;
