import styled from 'styled-components';

export const ButtonStyle = styled.div`
  position: fixed;
  bottom: 2rem;
  width: 100%;
  display: flex;
  justify-content: center;
  pointer-events: none;
  flex-direction: column;
  align-items: center;
  z-index: 9999;

  .top {
    position: absolute;
    right: 2rem;
    background: #000;
  }

  .mouse {
    transition: 0.2s;
    animation: scroll 1.7s infinite;
    position: relative;
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    &.hide {
      opacity: 0 !important;
    }

    i {
      font-size: 1.85rem;

      @media (max-width: 500px) {
        font-size: 2.2rem;
      }
    }
  }

  @keyframes scroll {
    0% {
      top: 0rem;
      opacity: 1;
    }

    40% {
      top: 1rem;
      opacity: 1;
    }

    60% {
      top: 1.6rem;
      opacity: 0;
    }

    60.1%,
    100% {
      top: 0;
      opacity: 0;
    }
  }
`;
