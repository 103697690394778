import styled from 'styled-components';

import { SignInStyled } from '../signIn/signInStyled';

export const ForgetStyled = styled(SignInStyled)`
  .ant-btn {
    margin: 0;
  }
`;

export const PasswordStyled = styled(SignInStyled)`
  .idWrapper {
    position: relative;
  }
  .passwordButton {
    position: absolute;
    margin-top: 0;
    top: -2rem;
    right: 0;
  }
  .id {
    font-size: 1.5rem;
    font-weight: 600;
  }
  .ant-btn {
    margin: 2rem 0 0 0;
  }
`;
