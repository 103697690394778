import { ReactChild, useContext } from 'react';

import clsx from 'clsx';
import { FormikContext } from 'formik';

import { HelperTxtDiv } from './formStyle';

interface FormFieldTypes {
  label: string | undefined;
  children: ReactChild;
  error: string;
}

export default function FormField({ label, children, error }: FormFieldTypes) {
  const formik = useContext(FormikContext);
  const isError = Object.values(formik.touched).length;

  return (
    <HelperTxtDiv
      className={clsx('shared-formField', isError && error && 'error')}
    >
      {label && <div className="label">{label}</div>}

      {children}

      <div className="helperText">{isError ? error : ''}</div>
    </HelperTxtDiv>
  );
}
