import styled from 'styled-components';

export const MenuItemStyled = styled.div`
  height: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1.25rem 0 1.25rem;
  cursor: pointer;
  color: #909399;
  position: relative;
  @media (max-width: 1000px) {
    font-size: 0.75rem;
  }
  @media (max-width: 600px) {
    font-size: 0.6rem;
  }

  &.active {
    color: #000;
  }
`;
