import moment from 'moment';

const isProgress = (date: Date, minutes: number) => {
  const beforeTime = moment(date);
  const afterTime = beforeTime.add(minutes, 'minutes');

  return moment(date).isBetween(beforeTime, afterTime, undefined, '[]');
};

export default isProgress;
