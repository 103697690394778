import styled from 'styled-components';

export const SignInStyled = styled.div`
  .forget {
    font-family: GmarketSansTTF;
    font-size: 0.813rem;
    font-weight: 500;
    color: #7d8291;
    text-align: right;
    margin-top: -0.5rem;
    text-decoration: underline;
    text-underline-position: under;
    span {
      cursor: pointer;
    }

    @media (max-width: 700px) {
      font-size: 10px;
    }
  }

  .ant-btn {
    border-radius: 7px;
    height: 56px !important;
    font-family: GmarketSansTTF;
    font-size: 1.25rem;
    font-weight: 500;
    margin-top: 1rem;
    margin-bottom: 2rem;
  }

  .signUpLink {
    text-align: center;
    font-family: GmarketSansTTF;
    font-size: 1rem;
    font-weight: 500;
    color: #7d8291;

    @media (max-width: 700px) {
      font-size: 10px;
    }

    em {
      text-decoration: underline;
      text-underline-position: under;
    }
  }
  .itemWrapper {
    display: flex;
    justify-content: space-around;
    max-width: 50%;
    margin: 0 auto;
  }
  .item {
    cursor: pointer;
    & img {
      width: 3rem;
      height: 3rem;
      object-fit: contain;
      margin: 0 auto;
    }
    .text {
      margin-top: 0.25rem;
      font-size: 0.75rem;
      font-weight: 600;
      text-align: center;
      color: black !important;
      text-decoration: none;
    }
  }
`;
