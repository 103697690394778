import { memo, ReactNode } from 'react';

import { Input as AntInput, InputProps } from 'antd';
import { SearchProps } from 'antd/lib/input';
import clsx from 'clsx';
import { useField } from 'formik';
import { AnimatePresence, motion } from 'framer-motion';

import { FormField } from '@web/shared/components/form/Form';

import passwordHiddenImage from '@web/client/src/assets/input/password-hidden.svg';
import passwordViewImage from '@web/client/src/assets/input/password-view.svg';

import { InputBox, ButtonProps } from './formStyle';

const { TextArea } = AntInput;

export interface InputTypes extends InputProps, SearchProps, ButtonProps {
  name: string;
  label?: string;
  helperText?: string;
  theme?: 'default' | 'simple';
  multiLine?: boolean;
  minRows?: number;
  maxRows?: number;
  showCount?: boolean;
  placeholder?: string;
  buttonText?: ReactNode;
  onButtonClick?: () => void;
  password?: boolean;
  inputDisabled?: boolean;
}

export default memo(function Input({
  name,
  helperText,
  label,
  theme = 'default',
  multiLine = false,
  placeholder,
  minRows = 1,
  maxRows = 30,
  showCount = false,
  buttonText,
  onButtonClick,
  buttonWidth,
  disableButtonCursorPointer = false,
  password,
  inputDisabled,
  ...props
}: InputTypes) {
  const [field, meta, helpers] = useField(name);
  const onChange = field.onChange;
  const value = field.value;
  const InnerInput = buttonText
    ? AntInput.Search
    : password
    ? AntInput.Password
    : AntInput;

  if (buttonText) {
    props.enterButton = buttonText;
    props.onSearch = onButtonClick;
  }

  return (
    <InputBox
      buttonWidth={buttonWidth}
      disableButtonCursorPointer={disableButtonCursorPointer}
      className={clsx(
        'shared-input',
        theme,
        disableButtonCursorPointer && 'disableButtonCursorPointer',
      )}
    >
      <AnimatePresence>
        <FormField label={label} error={meta.error as string}>
          {multiLine ? (
            <TextArea
              name={name}
              onChange={!inputDisabled ? onChange : () => undefined}
              value={value}
              autoSize={{ minRows: minRows, maxRows: maxRows }}
              placeholder={placeholder}
              showCount={showCount}
            />
          ) : (
            <InnerInput
              name={name}
              onChange={!inputDisabled ? onChange : () => undefined}
              value={value}
              placeholder={placeholder}
              {...(password
                ? {
                    iconRender: visible =>
                      visible ? (
                        <div>
                          <motion.img
                            width="20"
                            initial={{ x: '-40%', y: '-50%', opacity: 0 }}
                            animate={{ x: '-50%', y: '-50%', opacity: 1 }}
                            transition={{ duration: 0.3 }}
                            src={passwordViewImage}
                            key={passwordViewImage}
                            alt="icon"
                          />
                        </div>
                      ) : (
                        <div>
                          <motion.img
                            width="20"
                            initial={{ x: '-40%', y: '-50%', opacity: 0 }}
                            animate={{ x: '-50%', y: '-50%', opacity: 1 }}
                            transition={{ duration: 0.3 }}
                            src={passwordHiddenImage}
                            key={passwordHiddenImage}
                            alt="icon"
                          />
                        </div>
                      ),
                  }
                : {})}
              {...props}
            />
          )}
        </FormField>
      </AnimatePresence>
    </InputBox>
  );
});
