import { initReactI18next } from 'react-i18next';

import { LANGUAGE } from '../domains/commonDomain';
import { isDevelopmentMode } from './environmentUtils';

import i18n from 'i18next';

async function loadLocaleMessages(currentLanguage: string) {
  let lang = '';
  if (['ko', 'ko-KR'].includes(currentLanguage)) {
    lang = 'ko_KR';
  } else {
    lang = 'en_US';
  }
  const data = await import(`../locales/${lang}.json`);
  const locales = data.default;
  return { [currentLanguage]: locales };
}

export let $: any = () => {};

export async function init(currentLanguage: string) {
  return i18n
    .use(initReactI18next)
    .init({
      debug: isDevelopmentMode,
      load: 'currentOnly',
      interpolation: {
        escapeValue: true,
        prefix: '{',
        suffix: '}',
      },
      lng: currentLanguage,
      fallbackLng: LANGUAGE.KO,
      resources: await loadLocaleMessages(currentLanguage),
    })
    .then(t => {
      $ = t;
    });
}
