import { useHistory, useLocation } from 'react-router-dom';

import { MenuItemStyled } from './styled';

interface MenuItemTypes {
  name: string;
  link: string | undefined;
}

export default function MenuItem({ name, link }: MenuItemTypes) {
  const history = useHistory();
  const { pathname } = useLocation();

  const move = (link: string | undefined) => {
    history.push(link as string);
  };

  return (
    <MenuItemStyled
      className={pathname === link ? 'active' : ''}
      onClick={() => move(link)}
    >
      {name}
    </MenuItemStyled>
  );
}
