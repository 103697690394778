import { HTMLAttributes } from 'react';

import clsx from 'clsx';
import styled from 'styled-components';

import { IFile } from '@web/shared/types/strapi';
import { getFileURL, TFormat } from '@web/shared/utils/strapi';

import noImage from '@web/client/src/assets/image/noImage.webp';

interface IProps extends HTMLAttributes<HTMLDivElement> {
  width?: string;
  height?: string;
  src: string | IFile | undefined | null;
  alt?: string;
  isBGI?: boolean;
  radius?: string;
  className?: string;
  sizeFormat?: TFormat;
}

interface IStyledProps extends IProps {
  src: string;
}

const BGIStyled = styled.div<IStyledProps>`
  ${props => props.width && `width: ${props.width};`}
  ${props => props.height && `height: ${props.height}; `}
  ${props => props.radius && `border-radius: ${props.radius};`}
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url('${props => props.src}');
  max-width: 100%;
`;

const ImgStyled = styled.img<IStyledProps>`
  max-width: 100%;
  ${props => props.width && `width: ${props.width};`}
  ${props => props.height && `height: ${props.height};`}
  ${props => props.radius && `border-radius: ${props.radius};`}
  display: block;
  object-fit: cover;
  object-position: center;
`;

export default function Image(props: IProps) {
  const isBGI =
    props.isBGI === undefined
      ? Boolean(
          props.width &&
            props.width !== 'auto' &&
            props.height &&
            props.height !== 'auto',
        )
      : props.isBGI;

  const src = !props.src
    ? noImage
    : typeof props.src === 'string'
    ? props.src
    : getFileURL(props.src, props.sizeFormat || 'original');

  return isBGI ? (
    <BGIStyled
      {...{ isBGI, ...props }}
      className={clsx('image', 'BGI', props.className)}
      src={src}
    />
  ) : (
    <ImgStyled
      {...{ isBGI, ...props }}
      className={clsx('image', 'img', props.className)}
      src={src}
    />
  );
}
