import { INativeInfoResult } from '../utils/getNativeInfo';

export const getNativeInfo = (): Promise<INativeInfoResult> => {
  const result: INativeInfoResult = {
    result: {
      language: window.navigator.language,
    },
  };

  return new Promise((resolve): void => {
    resolve(result);
  });
};
