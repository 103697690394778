export const menus = [
  {
    name: 'GEF',
    link: '/gef',
    subMenus: [
      {
        name: '재단소개',
        link: '/gef',
      },
      {
        name: '재단구조',
        link: '/gef#org',
      },
      {
        name: '소속멤버',
        link: '/gef#member',
      },
      {
        name: '히스토리',
        link: '/gef#history',
      },
    ],
  },
  {
    name: 'DA센터',
    link: '/data-center',
    subMenus: [
      {
        name: '센터소개',
        link: '/data-center',
      },
      {
        name: '데이터검색',
        link: '/data-center#search',
      },
    ],
  },
  {
    name: 'BI센터',
    link: '/coaching',
    subMenus: [
      {
        name: '센터소개',
        link: '/coaching',
      },
      {
        name: '멘토단',
        link: '/coaching#member',
      },
      {
        name: '멘토스케쥴',
        link: '/coaching#calendar',
      },
      {
        name: '멘토링신청',
        link: '/coaching?modal=MentoApply',
      },
    ],
  },
  {
    name: 'SB센터',
    link: '/business',
    subMenus: [
      {
        name: '센터소개',
        link: '/business',
      },
      {
        name: '지원사업 리스트',
        link: '/business#list',
      },
      {
        name: '지원사업 스케쥴',
        link: '/business#calendar',
      },
      {
        name: '지원사업 신청',
        link: '/business?modal=SupportList',
      },
    ],
  },
  {
    name: '커뮤니티',
    link: '/community/list/notice',
    subMenus: [
      {
        name: '공지사항',
        link: '/community/list/notice',
      },
      {
        name: '뉴스',
        link: '/community/list/news',
      },
      {
        name: '1:1 문의',
        link: '/inquiry/list',
      },
      {
        name: '자료실',
        link: '/community/list/files',
      },
      {
        name: 'FAQ',
        link: '/community/list/faq',
      },
    ],
  },
];
