import { useHistory } from 'react-router-dom';

import Image from '@web/client/src/components/ui/Image';

import newLogo from '@web/client/src/assets/icons/newLogo.png';

import { LogoStyle } from './headerStyle';

export default function Logo() {
  const history = useHistory();

  return (
    <LogoStyle>
      <div className="logo" onClick={() => history.push('/')}>
        <Image src={newLogo} alt="logoMark" className="mark" />
        <div>
          <div className="name">SU 센터</div>
          <div className="sub-name">GEF온라인창업지원단</div>
          <div className="en">ONLINE STARTUP SUPPORT CENTER</div>
        </div>
        {/* <Image src={logo} alt="logo" className="white" />
              <Image src={logo_black} alt="logo" className="black" /> */}
      </div>
    </LogoStyle>
  );
}
