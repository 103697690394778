import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';

import { Button, ModalProps, Modal as AntModal } from 'antd';
import { useFormik } from 'formik';
import { isEmpty } from 'lodash';
import * as yup from 'yup';

import Modal from '@web/client/src/components/ui/Modal';
import useModal from '@web/client/src/hooks/useModal';
import { useActionAPI, useGetAPI } from '@web/shared/apis';
import { Form, Input } from '@web/shared/components/form/Form';
import { IAnyObject } from '@web/shared/types/etc';

import EmailValidation from '../signUp/EmailValidation';
import PasswordModal from './PasswordModal';
import { ForgetStyled } from './style';

type IProps = ModalProps;

export default function ForgetModal(props: IProps) {
  const [tempID, setTempID] = useState(-1);
  const [completed, setCompleted] = useState(false);
  const [myData, setMyData] = useState<IAnyObject>({});
  const [email, setEmail] = useState('');

  const history = useHistory();
  const location = useLocation();
  const { data } = useGetAPI('users', {
    enabled: location.search === '?modal=Forget' && Boolean(email),
    email,
  });
  const { action } = useActionAPI('temps');

  const { i18n } = useTranslation();

  const { open } = useModal();

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    onSubmit: () => {
      if (!completed) {
        AntModal.error({
          title: '인증 필요',
          content: '이메일 인증 진행이 필요합니다.',
        });
        return;
      }
      history.push(location.pathname);
      open('Password');
    },
  });

  const onClickSendEmail = async () => {
    const emailValidation = yup.object().shape({
      email: yup
        .string()
        .email('이메일 형식으로 입력해주세요.')
        .required('이메일을 입력해주세요'),
    });
    emailValidation
      .validate({ email: formik.values.email })
      .then(async () => {
        setEmail(formik.values.email);
      })
      .catch(() => {
        AntModal.error({
          title: '부정확한 이메일 값',
          content: '정확한 이메일 주소를 입력해주세요.',
        });
      });
  };
  const isKor = ['ko', 'ko-KR'].includes(i18n.language);
  const lang = isKor ? 'kor' : 'eng';

  useEffect(() => {
    if (email && data) {
      if (data && !isEmpty(data)) {
        setMyData(data);
        const sendEmailWrapper = async () => {
          const query: IAnyObject = {
            to: formik.values.email,
            lang,
            type: 'validation',
          };
          const id = await action('post', query);
          setTempID(id);
        };
        sendEmailWrapper();
      } else if (isEmpty(data)) {
        AntModal.error({
          title: '부정확한 이메일',
          content: '등록된 이메일이 아닙니다. 이메일을 확인해주세요.',
        });
      }
    }
  }, [data]);

  useEffect(() => {
    if (
      (myData === undefined || null || Object.keys(myData).length === 0) &&
      location.search === '?modal=Password'
    ) {
      history.push({ search: '?modal=Forget' });
    }
  }, [location]);

  const modalOpen = data && !isEmpty(data) && Boolean(tempID > 0);

  return (
    <>
      <Modal
        modalID="Forget"
        title="아이디 찾기"
        small
        centered
        destroyOnClose
        onCancel={() => history.push('/')}
        {...props}
      >
        <ForgetStyled>
          <Form formik={formik} hasEnter={false}>
            <Input
              name="email"
              label="e-Mail"
              type="email"
              buttonText={!completed ? '인증메일 보내기' : '이메일 변경'}
              inputDisabled={completed}
              buttonWidth="8rem"
              onButtonClick={
                !completed
                  ? onClickSendEmail
                  : () => {
                      setCompleted(false);
                      formik.setFieldValue('email', '');
                    }
              }
              onKeyDown={e => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                }
              }}
            />
          </Form>
          <Button onClick={formik.submitForm} type="primary" block>
            아이디 찾기
          </Button>
        </ForgetStyled>
      </Modal>
      {completed && myData?.id && <PasswordModal myID={myData.id} />}
      <EmailValidation
        visible={modalOpen}
        closeModal={() => {
          setTempID(-1);
          setEmail('');
        }}
        setCompleted={(v: boolean) => setCompleted(v)}
        data={{ to: formik.values.email, id: tempID, lang }}
      />
    </>
  );
}
