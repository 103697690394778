import { ButtonHTMLAttributes } from 'react';
import { ReactNode } from 'react';

import clsx from 'clsx';

import Image from '@web/client/src/components/ui/Image';
import { useTrans } from '@web/client/src/hooks/useTrans';
import SpinLoading from '@web/shared/components/spinLoading/SpinLoading';

import nextImage from '@web/client/src/assets/signUp/next.svg';
import prevImage from '@web/client/src/assets/signUp/prev.svg';

import { NextPrevButtonStyled } from './signUpStyled';

interface Props {
  next?: boolean;
  prev?: boolean;
}

interface NextPrevButtonProps
  extends ButtonHTMLAttributes<HTMLButtonElement>,
    Props {
  text?: ReactNode;
  children?: ReactNode;
  loading?: boolean;
}

export default function NextPrevButton({
  next = true,
  prev = false,
  text,
  className,
  children,
  loading,
  ...props
}: NextPrevButtonProps) {
  const $ = useTrans();
  return (
    <NextPrevButtonStyled
      className={clsx(
        'nextPrevButton',
        prev ? 'prevButton' : 'nextButton',
        className,
      )}
      next={next}
      prev={prev}
      {...props}
    >
      {children}
      {prev ? (
        <>
          <Image width="2rem" src={prevImage} alt="" />
          {text || $('이전단계')}
        </>
      ) : (
        <>
          {text || $('다음단계')}
          {loading ? (
            <div className="loading">
              <SpinLoading />
            </div>
          ) : (
            <Image width="2rem" src={nextImage} alt="" />
          )}
        </>
      )}
    </NextPrevButtonStyled>
  );
}
