import { Fragment, ReactNode } from 'react';

import { Modal, ModalFuncProps } from 'antd';
import styled from 'styled-components';

import effect1Image from '@web/client/src/assets/confirm/effect-1.svg';
import effect2Image from '@web/client/src/assets/confirm/effect-2.svg';
import effect3Image from '@web/client/src/assets/confirm/effect-3.svg';

import Image from './Image';

interface IConfirmOptions extends ModalFuncProps {
  title: ReactNode;
  content: ReactNode;
  effect?: boolean;
}

const EffectTitleStyled = styled.div`
  position: relative;
  display: inline-block;

  .image {
    position: absolute;
  }

  .effect1 {
    left: -65px;
    top: -90px;
  }

  .effect2 {
    right: -30px;
    bottom: 2.3rem;
  }

  .effect3 {
    right: -140px;
    bottom: 1.2rem;
  }
`;

const EffectTitle = ({ title }: { title: ReactNode }) => {
  return (
    <EffectTitleStyled>
      <Image height="9rem" src={effect1Image} alt="icon" className="effect1" />
      <Image height="9rem" src={effect2Image} alt="icon" className="effect2" />
      <Image height="7rem" src={effect3Image} alt="icon" className="effect3" />
      {title}
    </EffectTitleStyled>
  );
};

export const confirm = (options: IConfirmOptions) => {
  const type = options.type || 'info';

  const content =
    typeof options.content === 'string' ? (
      <>
        {options.content.split('\n').map((text, i) => (
          <Fragment key={i}>
            {text}
            <br />
          </Fragment>
        ))}
      </>
    ) : (
      options.content
    );

  const title = options.effect ? (
    <EffectTitle title={options.title} />
  ) : (
    options.title
  );

  return Modal[type]({
    okText: '확인',
    cancelButtonProps: {
      type: 'primary',
    },
    ...options,
    content,
    title,
  });
};
