import { useHistory, useLocation } from 'react-router-dom';

import { Modal } from 'antd';

import { useTrans } from '@web/client/src/hooks/useTrans';

import { ProgressBarStyled, ListStyled } from './style';

interface IProgressBar {
  now: number;
  step: number;
}

export default function ProgressBar({ now, step }: IProgressBar) {
  const $ = useTrans();
  const history = useHistory();
  const location = useLocation();

  const userStepsContent = [
    { title: `${$('필수질문')}`, content: `${$('창업 관련 질문 등')}` },
    { title: `${$('추가정보')}`, content: `${$('주소 등')}` },
    { title: `${$('사업자정보')}`, content: `${$('사업자 번호 등')}` },
  ];
  const onClickNumber = (i: number) => {
    if (i === now) {
      return;
    }
    if (i <= step) {
      Modal.confirm({
        title: `${$('주의 요망')}`,
        content: `${$(
          '변경된 내용이 있을 경우 데이터가 저장되지 않습니다. \n 단계 인증으로 넘어가시겠습니까?',
          [`${i + 1}`],
        )}`,
        onOk: () => {
          history.push(`${location.pathname}?modal=UserAdd&steps=${i}`);
        },
      });
    } else {
      Modal.error({
        title: `${$('인증 필요')}`,
        content: `${$('단계를 진행하기 전, 단계의 인증이 필요합니다', [
          `${i + 1}`,
          `${step + 1}`,
        ])}`,
      });
    }
  };
  return (
    <>
      <h3>{`${$('인증 진행 - 현재 나의 인증 단계 : 단계', [`${step}`])}`}</h3>
      <ProgressBarStyled>
        {userStepsContent.map((item, i) => {
          let whatClass = '';
          if (i < now) {
            whatClass = 'is-complete';
          } else if (i === now) {
            whatClass = 'is-active';
          }

          return (
            <ListStyled className={whatClass} key={i}>
              <div onClick={() => onClickNumber(i)} />
              <span>{item.title}</span>
            </ListStyled>
          );
        })}
      </ProgressBarStyled>
    </>
  );
}
