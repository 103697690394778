import styled from 'styled-components';

export const FooterDiv = styled.footer`
  .title {
    font-family: GmarketSansTTF;
    font-size: 1.625rem;
    font-weight: bold;
    line-height: 1.08;
    color: rgb(0, 0, 0);
    margin-bottom: 1.1rem;
  }

  .subTitle {
    font-family: GmarketSansTTF;
    font-size: 1rem;
    font-weight: 500;
    color: rgb(82, 80, 78);
  }

  .rap {
    display: flex;

    .getInTouch {
      width: 50%;
      padding-right: 1.6rem;

      .title {
        font-size: 3rem;

        &:after {
          content: '';
          display: block;
          width: 6.313rem;
          height: 2px;
          background: #000;
          margin: 0.8rem 0 1rem;
        }
      }

      .subTitle {
        margin-bottom: 2rem;
      }

      input,
      textarea {
        border: solid 2px rgb(0, 0, 0) !important;
        padding-top: 0.5rem !important;
        padding-bottom: 0.5rem !important;
        resize: none !important;
        border-radius: 0 !important;
        padding-left: 0.7rem !important;
        font-family: 'Noto Sans KR' !important;

        &::placeholder {
          font-size: 1rem;
          font-weight: normal;
          color: rgb(82, 80, 78);
        }

        &:focus {
          border-color: #4e36cc !important;
        }
      }

      button {
        color: rgb(246, 241, 234);
        background: #000;
        border: none;
        padding: 1rem 2rem;
        height: auto;
        font-size: 0.8rem;
        letter-spacing: 0.7px;
        transition: 0.1s;

        &:hover {
          background: #4e36cc;
        }
      }
    }

    .right {
      width: 50%;
      display: flex;

      .side {
        width: 35%;

        .contactUs {
          .subTitle {
            line-height: 1.1;
            font-size: 0.9rem;
          }
        }

        .board {
          padding-top: 3rem;

          .menu {
            .item {
              display: flex;
              align-items: center;
              font-family: GmarketSansTTF;
              font-size: 0.813rem;
              cursor: pointer;

              &:hover {
                color: #4e36cc;
                font-weight: bold;

                .iconBox {
                  background: #000;

                  img {
                    filter: invert(1);
                  }
                }
              }

              &:not(:last-child) .iconBox {
                border-bottom: none;
              }

              .iconBox {
                cursor: pointer;
                background: #fff;
                border: 2px solid #000;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 3.1rem;
                height: 3.1rem;
                margin-right: 0.7rem;
                transition: 0.1s;

                img {
                  transition: 0.1s;
                }
              }
            }
          }
        }
      }

      .locationMap {
        width: 60%;
        .locationTitle {
          margin-bottom: 4rem;
        }

        .mapBox {
          border: 3px solid #000;
          cursor: pointer;
          overflow: hidden;
          height: 24.5rem;

          > div {
            width: 100% !important;
            height: 120% !important;

            .wrap_map {
              height: 100% !important;
            }

            .wrap_controllers.hide {
              display: none !important;
            }
          }
        }

        .info {
          margin-bottom: 1.3rem;
          color: rgb(82, 80, 78);
        }
      }
    }
  }

  .bot {
    background: #000;
    padding: 1.7rem 0 1.6rem;
    margin-top: 3.1rem;

    .rap {
      display: flex;
      justify-content: space-between;
      align-items: center;

      img {
        cursor: pointer;
      }
    }
  }
  .form {
    .shared-formField {
      margin-bottom: 2rem !important;
    }
    & textarea {
      height: 7.5rem !important;
    }
    @media (max-width: 1540px) {
      & textarea {
        height: 14rem !important;
      }
    }
  }

  @media (max-width: 1540px) {
    .rap {
      .right {
        flex-wrap: wrap;
        justify-content: space-between;

        .side {
          width: 100%;
          display: flex;

          .board {
            padding-top: 0;
            margin-left: 3rem;

            .menu {
              margin-top: -0.2rem;
            }
          }
        }

        .locationMap {
          width: 100%;
          height: 15rem;
          margin-bottom: 9.2rem;

          .mapBox {
            height: 100%;
            overflow: hidden;
          }
        }
      }
    }
  }

  @media (max-width: 720px) {
    > .rap:not(.bot) {
      > div:not(.topBtn) {
        width: 100% !important;
      }
    }

    .rap {
      flex-wrap: wrap;

      .getInTouch {
        margin-bottom: 2rem;
        padding-right: 0;
      }

      .right {
        .side {
          width: 30%;
          flex-direction: column;

          .board {
            margin-left: 0;
            margin-top: 2rem;
          }
        }

        .locationMap {
          width: 70%;
          height: 22.2rem;
          margin-bottom: 0;
        }
      }
    }
  }

  @media (max-width: 560px) {
    .rap {
      .right {
        .side {
          width: 38%;
        }

        .locationMap {
          width: 62%;
          height: 23rem;

          .info {
            font-size: 1rem;
          }
        }
      }
    }
  }

  @media (max-width: 440px) {
    .rap {
      .right {
        .side {
          width: 44%;
        }

        .locationMap {
          width: 56%;
        }
      }
    }
  }

  @keyframes topBtn {
    0% {
      transform: translateY(0rem);
    }
    50% {
      transform: translateY(-0.5rem);
    }
    100% {
      transform: translateY(0rem);
    }
  }

  .topBtn {
    &:hover {
      img {
        animation: topBtn 0.5s infinite;
      }
    }
  }
`;
