import styled from 'styled-components';

import Modal from '../ui/Modal';

interface Props {
  next?: boolean;
  prev?: boolean;
}

export const NextPrevButtonStyled = styled.button<Props>`
  display: inline-flex;
  align-items: center;
  padding: 1rem 1.5rem;
  border-radius: 7px;
  background-color: ${props => (props.prev ? '#faf9ff' : '#4e36cc')};
  font-family: 'GmarketSansTTF' !important;
  font-size: 1.25rem;
  font-weight: 500;
  color: ${props => (props.prev ? '#7c8393' : 'white')};
  border: none;
  outline: none;
  cursor: pointer;
  white-space: nowrap;
  ${props => props.prev && 'border: solid 1px #d9d4f3;'}

  img,.loading {
    width: 9px;
    ${props => (props.prev ? 'margin-right: 6rem;' : 'margin-left: 6rem;')};

    @media (max-width: 430px) {
      ${props => (props.prev ? 'margin-right: 4rem;' : 'margin-left: 4rem;')};
    }
  }
  .loading {
    margin: 0 1rem 0 5rem;

    @media (max-width: 430px) {
      margin: 0 1rem 0 3rem;
    }
  }
`;

export const TermsStyled = styled.div`
  .ant-divider {
    border-color: #d6d6d6;
  }

  .description {
    background-color: #faf9ff;
    border: 2px solid #d9d4f3;
    padding: 1.5rem;
    border-radius: 7px;
    font-family: 'GmarketSansTTF';
    font-size: 0.875rem;
    line-height: 1.57;
    color: #6b658c;
  }

  .info {
    font-family: 'GmarketSansTTF';
    font-size: 0.875rem;
    font-weight: 500;
    color: #6b658c;
    line-height: 1.57;
    word-break: keep-all;
  }
  .item {
    .checkBoxWrapper {
      display: flex;
      justify-content: space-between;
    }
  }

  .item + .item {
    margin-top: 2.5rem;
  }

  .ant-checkbox-wrapper + .description,
  .ant-checkbox-wrapper + .info {
    margin-top: 18px;
  }
`;

export const ModalStyled = styled(Modal)`
  .ant-modal-content {
    background-color: #e5eeef;
    .ant-modal-body div {
      text-align: center;
    }
    .info {
      & p:first-child {
        font-weight: 500;
        margin-bottom: 1rem;
      }
      & p {
        margin-bottom: 0;
      }
      margin-bottom: 2rem;
    }
    .time {
      color: #4e36cc;
      margin-bottom: 3rem;
    }
    & input {
      width: 100%;
    }
    .complete {
      display: block;
      margin: 4rem auto 2rem;
      width: 20rem;
      height: 3.6rem !important;
      border-radius: 0.6rem;
    }
    .errorDiv {
      font-size: 0.7rem;
      color: red;
      height: 0;
    }
    .spam {
      color: gray;
      font-size: 0.6rem;
    }
    .resend {
      font-size: 1.1rem;
      font-weight: 600;
      text-decoration: underline;
      cursor: pointer;
      margin-top: 1rem;
    }
  }
`;
