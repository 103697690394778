// @ts-ignore
import Visible from '@egjs/visible';
import { Modal } from 'antd';

import strapiAxios from '@web/shared/apis';
import { IAnyObject } from '@web/shared/types/etc';

export const slidePerVeiew = (max: number, size: number, sideView = 0.3) => {
  let view = Math.ceil(window.innerWidth / size);

  if (view > max) {
    view = max;
  }

  if (window.innerWidth < 550) {
    view = 1.2;
  }

  return view + sideView;
};

export const checkVisible = (
  target: string,
  onChange: (visible: boolean) => void,
  expandSize = -130,
) => {
  new Visible(document, {
    targetClass: target,
    expandSize: expandSize,
  })
    .on('change', function (e: any) {
      onChange(e.visible.length > 0 ? true : false);
    })
    .observe({ delay: 0, containment: false });
};

export const checkPassword = (password: string, isKor = true): boolean => {
  const num = password.search(/[0-9]/g);
  const eng = password.search(/[a-z]/gi);
  const spe = password.search(/[`~!@@#$%^&*|₩₩₩'₩";:₩/?]/gi);

  if (password.length < 8 || password.length > 20) {
    if (isKor) {
      Modal.error({
        title: '비밀번호 길이 오류',
        content: '비밀번호는 8자리 ~ 20자리 이내로 입력해주세요.',
      });
    } else {
      Modal.error({
        title: 'Password length error',
        content: 'Please enter the password within 8 to 20 digits.',
      });
    }

    return false;
  }
  if (password.search(/\s/) !== -1) {
    if (isKor) {
      Modal.error({
        title: '비밀번호 공백 존재',
        content: '비밀번호는 공백없이 입력해주세요.',
      });
    } else {
      Modal.error({
        title: 'Password gap error',
        content: 'Please enter the password without a space.',
      });
    }

    return false;
  }
  if (num < 0 || eng < 0 || spe < 0) {
    if (isKor) {
      Modal.error({
        title: '비밀번호 필수 누락',
        content: '영문, 숫자, 특수문자를 혼합하여 입력해주세요.',
      });
    } else {
      Modal.error({
        title: 'Missing the essential elements of the password.',
        content:
          'Please input a mixture of English, numbers, and special characters.',
      });
    }

    return false;
  }
  return true;
};

export const sendEmail = async (query: IAnyObject) => {
  const queryString = Object.entries(query)
    .map(([key, value], i) =>
      i === 0 ? `?${key}=${value}` : `&${key}=${value}`,
    )
    .join('');
  strapiAxios.get(`/mail${queryString}`);
};

export const checkIdNumber = (idNumber: string) => {
  const jumin = idNumber.replace(' - ', '').split('');
  const bits = [2, 3, 4, 5, 6, 7, 8, 9, 2, 3, 4, 5];
  let sum = 0;
  bits.forEach((bit, i) => {
    sum += Number(jumin[i]) * bit;
  });

  const lastNum = Number(jumin[jumin.length - 1]);
  const resultNum = (11 - (sum % 11)) % 10;

  if (lastNum !== resultNum) {
    return false;
  }

  return true;
};

export const checkPassportNumber = (number: string) => {
  const oldNum = '(?<=[^0-9a-zA-Z])([a-zA-Z]{2}[0-9]{7})(?=[^0-9a-zA-Z])';
  const newNum =
    '(?<=[^0-9a-zA-Z])([M|S|R|O|D|m|s|r|o|d][0-9]{8})(?=[^0-9a-zA-Z])';
  if (number.match(oldNum)) {
    return true;
  }

  if (number.match(newNum)) {
    return true;
  }
  return false;
};
/* eslint-disable no-useless-escape */
const special_patterns = /[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/ ]/gim;
const typoReg = /[ㄱ-ㅎ|ㅏ-ㅣ]/gim;

export const checkTypo = (str: string) =>
  special_patterns.test(str) || typoReg.test(str);

export const removeTypo = (str: string) =>
  str.replace(special_patterns, '').replace(typoReg, '');
